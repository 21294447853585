import * as firebase from 'firebase/app';
import {getRemoteConfig, RemoteConfig} from "firebase/remote-config";
import {getAuth} from "firebase/auth";

import {FirebaseAuth} from "react-firebaseui";
import {Auth} from "@firebase/auth";
import {getAnalytics} from "firebase/analytics";
import {Analytics} from "@firebase/analytics";

class FirebaseApp {

    private static instance: FirebaseApp | undefined = undefined;

    private firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    }


    private app: firebase.FirebaseApp

    private remoteConfig: RemoteConfig;
    private firebaseAuth: Auth;
    private isUserLoggedIn: boolean
    private analytics: Analytics

    private constructor() {
        this.app = firebase.initializeApp(this.firebaseConfig)
        this.remoteConfig = getRemoteConfig(this.app)
        this.firebaseAuth = getAuth(this.app)
        this.analytics = getAnalytics(this.app)
        this.isUserLoggedIn = false
    }

    public static getInstance(): FirebaseApp {
        if (!FirebaseApp.instance) {
            FirebaseApp.instance = new FirebaseApp();
        }

        return FirebaseApp.instance;
    }

    public getRemoteConfig(): RemoteConfig {
        return this.remoteConfig
    }


    public getAnalytics(): Analytics {
        return this.analytics
    }

    public getFirebaseAuth(): Auth {
        return this.firebaseAuth
    }

    public setLoggingUser(isUserLoggedIn: boolean) {
        this.isUserLoggedIn = isUserLoggedIn
    }

    public isLoggingUser() {
        return this.isUserLoggedIn
    }


}


export default FirebaseApp;


