import {Startup} from "@domain/models/startup/Startup";
import {PostData, PostResultData} from "../domain/models/post/PostResponseV1";
import SearchRepository from "../domain/repository/search/ISearchRepository";
import {SavedSearchResult} from "@domain/models/search/SavedSearch";
import {
    CURRENT_CURRENCY_KEY,
    CURRENT_LOCATION_STORE_KEY, DEFAULT_CURRENCY,
    SEARCH_HISTORY_STORE_KEY,
    TOKEN_STORE_KEY
} from "@utils/Constants";
import {
    OTHERS_POSTS_API_ID,
    POST_RECOMMENDATIONS_API_ID,
    SEARCH_POSTS_API_ID,
    SEARCHES_API_ID
} from "@domain/models/startup/ServiceId";
import {ApiUtilsUtils} from "@utils/ApitUtils";
import FilterRequestBuilder from "@utils/filter/FilterRequestBuilder";
import {LocationDomain} from "@domain/models/search/SugestionType";
import api from "@data/Api";


export default class SearchApi implements SearchRepository {

    private startupConfig: Startup | undefined


    constructor(startupConfig: Startup | undefined) {
        this.startupConfig = startupConfig
    }

    getPostSuggestions(paramsMap: Map<string, string>): Promise<PostResultData | undefined> {
        const query = paramsMap?.get("query")

        if (query?.trim().length === 0) {
            return Promise.reject("Empty query");
        }

        return new Promise((resolve, reject) => {


            function convertNumbersToNumbers(obj: any): any {
                if (typeof obj === 'object') {
                    for (const key in obj) {
                        if (key === 'lat' || key === 'lon') {
                            obj[key] = Number(obj[key]);
                        } else if (typeof obj[key] === 'object') {
                            obj[key] = convertNumbersToNumbers(obj[key]);
                        }
                    }
                }
                return obj;
            }

            const filters = FilterRequestBuilder.getInstance().build(paramsMap);
            const filtersWithNumbers = convertNumbersToNumbers(filters);
            const currency = localStorage.getItem(CURRENT_CURRENCY_KEY) ?? DEFAULT_CURRENCY;

            const url = `${ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, SEARCH_POSTS_API_ID)}?q=${query}&currency=${currency}`;

            return api.post(url, JSON.stringify(filtersWithNumbers))
                .then(response => {
                    console.log("[searchPosts] response success");
                    return response.data;  // Axios automatically parses JSON and puts it in response.data
                })
                .catch(error => {
                    console.error("[searchPosts] error in response", error);
                    throw new Error(error.message);  // Pass the error message
                });

        });
    }


    getPostSearchResults(catalogId: string, paramsMap: Map<string, string>): Promise<PostResultData> {
        const currency = localStorage.getItem(CURRENT_CURRENCY_KEY) ?? DEFAULT_CURRENCY;

        function convertNumbersToNumbers(obj: any): any {
            if (typeof obj === 'object') {
                for (const key in obj) {
                    if (key === 'lat' || key === 'lon') {
                        obj[key] = Number(obj[key]);
                    } else if (typeof obj[key] === 'object') {
                        obj[key] = convertNumbersToNumbers(obj[key]);
                    }
                }
            }
            return obj;
        }

        const filters = FilterRequestBuilder.getInstance().build(paramsMap);
        const filtersWithNumbers = convertNumbersToNumbers(filters);

        const url = `${ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, SEARCH_POSTS_API_ID)}?&currency=${currency}`;

        // Build query parameters object
        const params: Record<string, string> = {
            category_id: catalogId
        };

        if (paramsMap?.has("q")) {
            params.q = paramsMap.get("q")!;
        }
        if (paramsMap?.has("page")) {
            params.page = paramsMap.get("page")!;
        }
        if (paramsMap?.has("per_page")) {
            params.per_page = paramsMap.get("per_page")!;
        }

        return api.post(url!!, filtersWithNumbers, {
            params, // Axios automatically serializes the params object into a query string
        })
            .then(response => {
                console.log("[getPostSearchResults] response success");
                return response.data;  // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                console.error("[getPostSearchResults] error in response", error);
                throw new Error(error.message);  // Pass the error message
            });
    }

    deleteSavedSearch(savedSearchId: string): Promise<boolean> {
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, SEARCHES_API_ID);
        const deleteSavedSearchUrl = `${url}/${savedSearchId}`;

        return api.delete(deleteSavedSearchUrl, {
            headers: {
                'Authorization': token
            }
        })
            .then(() => {
                console.log("[deleteSavedSearch] response success");
                return true; // Axios’s delete method doesn’t return a response body, so we return `true` directly
            })
            .catch(error => {
                console.error("[deleteSavedSearch] error in response", error);
                throw new Error(error.message); // Pass the error message
            });
    }

    getMySavedSearches(): Promise<SavedSearchResult> {
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, SEARCHES_API_ID);

        return api.get(url!!, {
            headers: {
                'Authorization': token
            }
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.error("[getMySavedSearches] error in response", error);
                throw new Error(error.message);
            });
    }

    saveSearchResult(query: string, categoryId: string): Promise<boolean> {
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, SEARCHES_API_ID);

        return api.post(url!!, {
            query,
            category_id: categoryId
        }, {
            headers: {
                'Authorization': token
            }
        })
            .then(() => {
                console.log("[saveSearchResult] response success");
                return true; // Axios’s post method doesn’t return a response body by default, so we return `true` directly
            })
            .catch(error => {
                console.error("[saveSearchResult] error in response", error);
                throw new Error(error.message); // Pass the error message
            });
    }

    getPostRecommendations(locationDomain: LocationDomain | undefined): Promise<PostResultData | undefined> {
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, POST_RECOMMENDATIONS_API_ID);

        return api.get(url!!, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then(response => {
                console.log("[getPostRecommendations] response success");
                return response.data; // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                console.error("[getPostRecommendations] error in response", error);
                throw new Error(error.message); // Pass the error message
            });
    }

    getSearchHistory(): PostData[] {
        const item = localStorage.getItem(SEARCH_HISTORY_STORE_KEY)
        if (item) {
            return JSON.parse(item)
        }
        return []
    }

    saveSearch(postData: PostData): void {
        const storedHistory = this.getSearchHistory();

        // Check if postData with the same id already exists in storedHistory
        const exists = storedHistory.some(historyItem => historyItem.id === postData.id);

        // If it doesn't exist, save it
        if (!exists) {
            const updatedHistory = [postData, ...storedHistory.slice(0, 9)]; // Limit to 10 items
            localStorage.setItem(SEARCH_HISTORY_STORE_KEY, JSON.stringify(updatedHistory));
        }
    }


    deleteSavedSearchHistory(postData: PostData): PostData[] {
        const storedHistory = this.getSearchHistory();
        const updatedHistory = storedHistory.filter(post => post.id !== postData.id);
        localStorage.setItem(SEARCH_HISTORY_STORE_KEY, JSON.stringify(updatedHistory))

        return this.getSearchHistory()
    }

    getCurrentLocation(): LocationDomain | undefined {
        const item = localStorage.getItem(CURRENT_LOCATION_STORE_KEY)
        if (item) {
            return JSON.parse(item)
        }
        return undefined
    }

    saveCurrentLocation(locationDomain: LocationDomain): void {
        localStorage.setItem(CURRENT_LOCATION_STORE_KEY, JSON.stringify(locationDomain))
    }

    getOtherPosts(userId: number,  page:number): Promise<PostResultData> {
        const url = `${ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, OTHERS_POSTS_API_ID)}?id=${userId}&page=${page}`;

        return api.get(url, {
            headers: {
                'Content-Type': 'application/json',
             }
        }).then(response => {
                 return response.data; // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                 throw new Error(error.message); // Pass the error message
            });
    }


}