import { PostResultData } from "@domain/models/post/PostResponseV1";
import SearchRepository from "../../repository/search/ISearchRepository";
import {LocationDomain} from "@domain/models/search/SugestionType";

export default class SearchRecommendationsUseCase {

    private searchRepository: SearchRepository

    public constructor(searchRepository: SearchRepository) {
        this.searchRepository = searchRepository
    }


    /**
     * Seach post suggestion list
     * @throws {Error} in case of getting non 2xx response or empty query 
     */
    public async searchRecommendations(locationDomain: LocationDomain | undefined) :  Promise<PostResultData | undefined> {
        return this.searchRepository.getPostRecommendations(locationDomain)
    }



}