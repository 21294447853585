import React, {useEffect, useState} from 'react';

import SearchViewModel from "@viewmodels/SearchViewModel";
import FilterMenu from "@presentation/views/components/FilterMenu";
import {Catalog} from "@domain/models/catalog/CatalogModel";

import ContentBox from "../../components/ContentBox/ContentBox";
import ArrowLeftIcon from '../../components/Icons/ArrowLeftIcon';
import {getCurrentCatalog} from './searchHelpers';

import Button from '../../components/Button/Button';
import R from "@strings/R"
import "./SearchResultsMobileFilters.scss";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import UrlUtils from "@utils/UrlUtils";

interface SearchResultsMobileFiltersProps {
    searchViewModel: SearchViewModel;
    onClose: () => void;
    total: number
}

const SearchResultsMobileFilters = ({searchViewModel, onClose, total}: SearchResultsMobileFiltersProps) => {
    const currentCatalog: Catalog | undefined = getCurrentCatalog();
    const onHideChanged = (show: boolean) => {
    }
    const [countFilter, setCountFilter] = useState(0)

    const {t} = useTranslation()

    const location = useLocation();
    useEffect(() => {
        const numCountFilter = UrlUtils.getCountFilter(location.search)
        setCountFilter(numCountFilter)
    }, [location])

    useEffect(() => {
        // Add the class when the component mounts
        document.body.classList.add("search-result-mobile-filters__open");

        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove("search-result-mobile-filters__open");
        };
    }, []);


    return (
        <div className="search-result-mobile-filters">
            <div className="search-result-mobile-filters__header">
                <button className="search-result-mobile-filters__close" onClick={onClose}>
                    <ArrowLeftIcon/>
                </button>

                <span>
          {t(R.searchResults_search_filtersMobileLabel)}
                    <span className="search-result-mobile-filters__header__count">{countFilter}</span>
        </span>
            </div>

            <div className="search-result-mobile-filters__body">
                <ContentBox>
                    <FilterMenu
                        className={"mt-4 search-result-mobile-filters__list"}
                        showFilter={true}
                        attributes={currentCatalog?.attributes}
                        onHideChanged={onHideChanged}
                        searchViewModel={searchViewModel}
                    />
                </ContentBox>
            </div>

            <div className="search-result-mobile-filters__footer">
                <Button type="primary" onClick={onClose}>
                     {t(R.searchResults_search_seeLabel, { count: total })}
                </Button>
            </div>
        </div>
    )
}

export default SearchResultsMobileFilters;
