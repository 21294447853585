import React from "react";

import "./StickyOnScroll.scss";

const StickyOnScroll = ({ children }: any) => {
    return (
        <div className="sticky-on-scroll-container">
            <div>{children}</div>
        </div>
    );
};

export default StickyOnScroll;
