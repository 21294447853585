import React, { ChangeEvent } from "react";
import FactoryMetaFilter from "@utils/factories/FactoryMetaFilter";
import MetaSearchUtils from "@utils/MetaSearchUtils";
import FilterRequestBuilder from "@utils/filter/FilterRequestBuilder";
import SearchViewModel from "@viewmodels/SearchViewModel";
import { FilterInputTypes } from "@enums/FilterInputTypes";
import { PerPageKeys } from "@enums/PerPageKeys";

interface SortResultsListFilterProps {
  searchViewModel: SearchViewModel;
}

const ItemsPerPageFilter = ({ searchViewModel }: SortResultsListFilterProps) => {
  const filter = FactoryMetaFilter.createFixMetaFilter(
    FilterInputTypes.ITEMS_PER_PAGE,
    searchViewModel,
    MetaSearchUtils.getItemPerPageRadiusAttribute(),
    (event: ChangeEvent<HTMLSelectElement>) => {
      FilterRequestBuilder.getInstance().setPerPageFilter(event.target.value as PerPageKeys)
    }
  );

  if (!filter) {
    return null;
  }

  return filter;
};

export default ItemsPerPageFilter;
