import React from "react";

import SearchMobile from "@presentation/views/components/Search/SearchExperience/SearchMobile/SearchMobile";
import StickyOnScroll from "../../StickyOnScroll/StickyOnScroll";
import {useIsTabletOrMobile} from "@utils/ScreenUtils";

const StickySearch = () => {

    // This can potentially be used for laptop / desktop screen
    if (!useIsTabletOrMobile()) {
        return null;
    }

    return (
        <StickyOnScroll>
            <SearchMobile />
        </StickyOnScroll>
    );
};

export default StickySearch;
