import CatalogApi from '@data/CatalogApi';
import GetTopCategoriesUseCase from '@domain/usecases/categories/GetTopCategoriesUseCase';
import "reflect-metadata";

import {injectable} from 'inversify';
import RailViewModel from '@viewmodels/RailViewModel';
import GetRailUseCase from '@domain/usecases/rail/GetRailUseCase';
import RailApi from '@data/RailApi';
import PostViewModel from '@viewmodels/PostViewModel';
import GetPostDetailsUseCase from '@domain/usecases/post/GetPostDetailsUseCase';
import PostApi from '@data/PostApi';
import SearchViewModel from '@viewmodels/SearchViewModel';
import SearchUseCase from '@domain/usecases/search/SearchUseCase';
import SearchApi from '@data/SearchApi';
import SearchLocationUseCase from '@domain/usecases/location/SearchLocationUseCase';
import LocationApi from '@data/LocationApi';
import UserViewModel from '@viewmodels/UserViewModel';
import GetUserAccountUseCase from '@domain/usecases/user/GetUserAccountUseCase';
import UserApi from '@data/UserApi';
import CategoriesViewModel from '@viewmodels/CategoriesViewModel';
import getStartupConfig from '@utils/remote_config/StartUpConfig';
import GetTokenUseCase from "@domain/usecases/auth/GetTokenUseCase";
import SignOutUseCase from "@domain/usecases/auth/SignOutUseCase";
import GetMyPostsUseCase from "@domain/usecases/post/GetMyPostsUseCase";
import DeleteMyPostUseCase from "@domain/usecases/post/DeleteMyPostUseCase";
import GetMyFavouritesUseCase from "@domain/usecases/favourites/GetMyFavouritesUseCase";
import CreatePostUseCase from "@domain/usecases/post/CreatePostUseCase";
import DeleteFavouriteUseCase from "@domain/usecases/favourites/DeleteFavouriteUseCase";
import GetMySavedSearchesUseCase from "@domain/usecases/saved/GetMySavedSearchesUseCase";
import SearchRecommendationsUseCase from "@domain/usecases/search/SearchRecommendationsUseCase";
import {GetPostsInHistoryUseCase} from "@domain/usecases/search/GetPostsInHistoryUseCase";
import {DeletePostFromHistoryUseCase} from "@domain/usecases/search/DeletePostFromHistoryUseCase";
import {SavePostInHistoryUseCase} from "@domain/usecases/search/SavePostInHistoryUseCase";
import {SaveCurrentLocationUseCase} from "@domain/usecases/search/SaveCurrentLocationUseCase";
import {GetCurrentLocationUseCase} from "@domain/usecases/search/GetCurrentLocationUseCase";
import SaveUserAccountUseCase from "@domain/usecases/user/SaveUserAccountUseCase";
import {GetOtherPostsUseCase} from "@domain/usecases/search/GetOtherPostsUseCase";

export interface IProvider<T> {
    provide(): T;
}

@injectable()
export class CategoriesProvider implements IProvider<CategoriesViewModel> {
    provide() {
        return new CategoriesViewModel(new GetTopCategoriesUseCase(new CatalogApi(getStartupConfig())));
    }
}


@injectable()
export class PostProvider implements IProvider<PostViewModel> {
    provide() {
        return new PostViewModel(
            new GetPostDetailsUseCase(new PostApi(getStartupConfig())),
            new GetMyPostsUseCase(new PostApi(getStartupConfig())),
            new DeleteMyPostUseCase(new PostApi(getStartupConfig())),
            new GetMyFavouritesUseCase(new PostApi(getStartupConfig())),
            new CreatePostUseCase(new PostApi(getStartupConfig())),
            new DeleteFavouriteUseCase(new PostApi(getStartupConfig()))
        );
    }
}


@injectable()
export class UserProvider implements IProvider<UserViewModel> {
    provide() {
        return new UserViewModel(new GetUserAccountUseCase(new UserApi(getStartupConfig())),
            new GetTokenUseCase(new UserApi(getStartupConfig())),
            new SignOutUseCase());
    }
}


@injectable()
export class RailsProvider implements IProvider<RailViewModel> {
    provide() {
        return new RailViewModel(new GetRailUseCase(new RailApi(getStartupConfig())));
    }
}


@injectable()
export class SearchProvider implements IProvider<SearchViewModel> {
    provide() {
        return new SearchViewModel(
            new SearchUseCase(new SearchApi(getStartupConfig())),
            new SearchLocationUseCase(new LocationApi(getStartupConfig())),
            new GetMySavedSearchesUseCase(new SearchApi(getStartupConfig())),
            new SearchRecommendationsUseCase(new SearchApi(getStartupConfig())),
            new GetPostsInHistoryUseCase(new SearchApi(getStartupConfig())),
            new DeletePostFromHistoryUseCase(new SearchApi(getStartupConfig())),
            new SavePostInHistoryUseCase(new SearchApi(getStartupConfig()))
        );
    }
}


//Use cases

@injectable()
export class GetPostDetailsUseCaseProvider implements IProvider<GetPostDetailsUseCase> {
    provide() {
        return new GetPostDetailsUseCase(new PostApi(getStartupConfig()))
    }
}


@injectable()
export class GetMyPostsUseCaseProvider implements IProvider<GetMyPostsUseCase> {
    provide() {
        return new GetMyPostsUseCase(new PostApi(getStartupConfig()))
    }
}

@injectable()
export class DeleteMyPostUseCaseProvider implements IProvider<DeleteMyPostUseCase> {
    provide() {
        return new DeleteMyPostUseCase(new PostApi(getStartupConfig()))
    }
}


@injectable()
export class GetMyFavouritesUseCaseProvider implements IProvider<GetMyFavouritesUseCase> {
    provide() {
        return new GetMyFavouritesUseCase(new PostApi(getStartupConfig()))
    }
}

@injectable()
export class CreatePostUseCaseProvider implements IProvider<CreatePostUseCase> {
    provide() {
        return new CreatePostUseCase(new PostApi(getStartupConfig()))
    }
}

@injectable()
export class DeleteFavouriteUseCaseProvider implements IProvider<DeleteFavouriteUseCase> {
    provide() {
        return new DeleteFavouriteUseCase(new PostApi(getStartupConfig()))
    }
}

@injectable()
export class GetUserAccountUseCaseProvider implements IProvider<GetUserAccountUseCase> {
    provide() {
        return new GetUserAccountUseCase(new UserApi(getStartupConfig()))
    }
}

@injectable()
export class SaveUserAccountUseCaseProvider implements IProvider<SaveUserAccountUseCase> {
    provide() {
        return new SaveUserAccountUseCase(new UserApi(getStartupConfig()))
    }
}

@injectable()
export class GetTokenUseCaseProvider implements IProvider<GetTokenUseCase> {
    provide() {
        return new GetTokenUseCase(new UserApi(getStartupConfig()))
    }
}

@injectable()
export class SignOutUseCaseProvider implements IProvider<SignOutUseCase> {
    provide() {
        return new SignOutUseCase()
    }
}

@injectable()
export class SearchUseCaseProvider implements IProvider<SearchUseCase> {
    provide() {
        return new SearchUseCase( new SearchApi(getStartupConfig()))
    }
}

@injectable()
export class SearchLocationUseCaseProvider implements IProvider<SearchLocationUseCase> {
    provide() {
        return new SearchLocationUseCase(new LocationApi(getStartupConfig()))
    }
}



@injectable()
export class GetMySavedSearchesUseCaseProvider implements IProvider<GetMySavedSearchesUseCase> {
    provide() {
        return new GetMySavedSearchesUseCase(new SearchApi(getStartupConfig()))
    }
}


@injectable()
export class SearchRecommendationsUseCaseProvider implements IProvider<SearchRecommendationsUseCase> {
    provide() {
        return new SearchRecommendationsUseCase(new SearchApi(getStartupConfig()))
    }
}


@injectable()
export class GetPostsInHistoryUseCaseProvider implements IProvider<GetPostsInHistoryUseCase> {
    provide() {
        return new GetPostsInHistoryUseCase(new SearchApi(getStartupConfig()))
    }
}

@injectable()
export class DeletePostFromHistoryUseCaseProvider implements IProvider<DeletePostFromHistoryUseCase> {
    provide() {
        return new DeletePostFromHistoryUseCase(new SearchApi(getStartupConfig()))
    }
}

@injectable()
export class SavePostInHistoryUseCaseProvider implements IProvider<SavePostInHistoryUseCase> {
    provide() {
        return new SavePostInHistoryUseCase(new SearchApi(getStartupConfig()))
    }
}

@injectable()
export class SaveCurrentLocationUseCaseProvider implements IProvider<SaveCurrentLocationUseCase> {
    provide() {
        return new SaveCurrentLocationUseCase(new SearchApi(getStartupConfig()))
    }
}

@injectable()
export class GetCurrentLocationUseCaseProvider implements IProvider<GetCurrentLocationUseCase> {
    provide() {
        return new GetCurrentLocationUseCase(new SearchApi(getStartupConfig()))
    }
}

@injectable()
export class GetOtherPostsUseCaseProvider implements IProvider<GetOtherPostsUseCase> {
    provide() {
        return new GetOtherPostsUseCase(new SearchApi(getStartupConfig()))
    }
}








