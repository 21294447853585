import React from 'react';

import './ValidationMessage.scss';

interface ValidationMessageProps {
  text: string;
};

const ValidationMessage: React.FC<ValidationMessageProps> = ({ text }) => {
  return (
    <span className="validation-message">{text}</span>
  );
};

export default ValidationMessage;
