import R from "@strings/R";
import React, {useEffect, useState, useMemo} from "react";
import {Accordion, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useInjection} from "@root/ioc.react";

import TYPES from "@di/viewmodels/ViewModelTypes";
import SearchUseCase from "@domain/usecases/search/SearchUseCase";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import {Attribute} from "@domain/models/catalog/CatalogModel";
import FactoryMetaFilter from "@utils/factories/FactoryMetaFilter";
import {FilterInputTypes} from "@enums/FilterInputTypes";
import SearchViewModel from "@viewmodels/SearchViewModel";
import MetaSearchUtils from "@utils/MetaSearchUtils";
import UrlUtils from "@utils/UrlUtils";
import {useLocation} from "react-router-dom";
import {SearchKeys} from "@enums/SearchKeys";
import {CURRENT_CURRENCY_KEY, DEFAULT_CURRENCY} from "@utils/Constants";

import "./FilterMenu.scss"

interface FilterMenuProps {
    showFilter: boolean;
    attributes: Attribute[] | undefined;
    searchViewModel: SearchViewModel;
    className: string;
    displayFilterLabel?: boolean;
    onHideChanged(show: boolean): void;
}

type FilterItem = {
    title: string;
    key: string;
    component: JSX.Element | undefined;
    keyActive: string;
};

const FilterMenu: React.FC<FilterMenuProps> = ({
  attributes,
  onHideChanged: onHideChanged,
  searchViewModel,
  className,
  displayFilterLabel
}: FilterMenuProps): JSX.Element => {
    const {t} = useTranslation()
    const activeKeys =  ['0', '1', '2', '3', '4'];
    const [filter, setFilters] = useState<FilterItem[]>([]);
    const location = useLocation();
    const searchUseCase = useInjection<IProvider<SearchUseCase>>(TYPES.SearchUseCase).provide();
    const paramsMap = UrlUtils.getParamsMap(location.search);

    const getSearchRadiusUnit = (searchUseCase: any): string => {
      return searchUseCase?.searchRepository?.startupConfig?.localization?.unit || '';
    }

    const searchRadiusUnit = useMemo(() => getSearchRadiusUnit(searchUseCase), [searchUseCase]);

    useEffect(() => {
        const filters: FilterItem[] = [
            {
                title: t(R.landingPage_location_hint),
                key: SearchKeys.SEARCH_LOCATION,
                component: FactoryMetaFilter.createFixMetaFilter(FilterInputTypes.SEARCH_LOCATION_INPUT, searchViewModel),
                keyActive: '0'
            },
            {
                title: `${t(R.searchResults_search_radius)} (${searchRadiusUnit})`,
                key: SearchKeys.SEARCH_LOCATION_RADIUS,
                component: FactoryMetaFilter.createFixMetaFilter(FilterInputTypes.SEARCH_RADIO_LIST, searchViewModel, MetaSearchUtils.getSearchRadiusAttribute()),
                keyActive: '1'
            }
        ]

        attributes?.map(
            attribute => {
                const keyActive = (filters.length + 1).toString()
                if (paramsMap.has(attribute.search_key) && !activeKeys.includes(keyActive))
                    activeKeys.push(filters.length.toString())
                filters.push(
                    {
                        title:  attribute.type === FilterInputTypes.COST
                            ? `${t(attribute.name_dict_key)} (${localStorage.getItem(CURRENT_CURRENCY_KEY) ??DEFAULT_CURRENCY })`
                            : t(attribute.name_dict_key),
                        key: attribute.name_dict_key,
                        component: FactoryMetaFilter.createMetaFilter(attribute),
                        keyActive: keyActive
                    }
                )
            }
        )

        setFilters(filters)
    }, [])

    return (
        <div className="filter-menu">
          {displayFilterLabel && <span className="filter-by"> {t(R.landingPage_filter_button)}</span>}
          <Accordion className={className} defaultActiveKey={activeKeys} alwaysOpen>
              {filter.map((filter, index) => (
                  <Accordion.Item eventKey={String(index)} className={`filter-${filter.key}`}>
                      <Accordion.Header>
                          {filter.title}&nbsp;&nbsp;
                      </Accordion.Header>
                      <Accordion.Body>
                          <Form>
                              {
                                  filter?.component
                              }
                          </Form>
                      </Accordion.Body>
                  </Accordion.Item>
              ))}
          </Accordion>
      </div>
    );


}

export default FilterMenu;
