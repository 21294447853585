import React from "react";

interface ArrowLeftIconProps {
    className?: string;
}

const ArrowLeftIcon = ({ className }: ArrowLeftIconProps) => {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 7L6.64706 13M1 7L6.64706 1M1 7H13"
          stroke="#001A1A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>      
    );
};

export default ArrowLeftIcon;
