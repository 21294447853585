export const BREAKPOINTS = {
    mobile: 320,
    mobileLarge: 425,
    mobileLandscape: 572,
    tablet: 846,
    laptop: 1256,
    desktop: 1530,
    desktopBig: 1921,
    desktopQHD: 2560,
    desktopUHD: 3840,
};
