import {useMediaQuery} from "@mui/material";
import {BREAKPOINTS} from "@utils/layout";


export const useIsTabletOrMobile = () => {

   return  useMediaQuery(
        `(max-width: ${BREAKPOINTS.laptop}px)`
    );
}


export const useIsMobile = () => {

    return  useMediaQuery(
        `(max-width: ${BREAKPOINTS.mobileLarge}px)`
    );
}
