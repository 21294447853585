import * as React from 'react';

const StickySearchIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 13L16 16M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z" stroke="#001A1A" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

export default StickySearchIcon;
