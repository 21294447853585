import React from "react";
import CopyrightSymbol from "../Icons/CopyrightSymbol";
import "./Copyright.scss";

export function CopyRight(): React.ReactElement {
    return (
      <div className="copyright">
          <span className="copyright__symbol">
            <CopyrightSymbol />
          </span>

          <span className="copyright__text">
            Rentallo Limited {new Date().getFullYear()}
          </span>
      </div>
    )
}
