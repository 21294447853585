import React, {useState, useEffect} from 'react';
import './i18n';
import container from './di/viewmodels/Container';
import AppRouter from './framework/presentation/views/components/routes/AppRouter';
import {Provider} from './ioc.react';
import OnMaintenance from "@presentation/views/pages/OnMaintenance";
import {useTranslation} from "react-i18next";
import ThemeProvider from 'react-bootstrap/ThemeProvider'

const isMaintenanceMode = process.env.REACT_APP_MAINTAINCE_MODE === 'true';

export const App = () => {
    const {i18n} = useTranslation();

    const currentRtl = i18n.dir(); // Get the current language from i18next

    useEffect(() => {
        document.documentElement.setAttribute('dir', currentRtl);
    }, [currentRtl]);
    return (
        <ThemeProvider
          breakpoints={['xxxxl', 'xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
        >
            {isMaintenanceMode ? (
                <OnMaintenance/>
            ) : (
                <Provider container={container}>
                    <AppRouter/>
                </Provider>
            )}
        </ThemeProvider>
    );
}
