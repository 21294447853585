import IUserRepository from "@domain/repository/user/IUserRepository";
import {UserRequest} from "@domain/models/user/UserRequest";

export default class SaveUserAccountUseCase {
    private userRepository: IUserRepository

    public constructor(userRepository: IUserRepository) {
        this.userRepository = userRepository
    }


    public async execute(useRequest: UserRequest): Promise<Boolean> {
        return new Promise(async (resolve, reject) => {
             await this.userRepository.saveUserAccount(useRequest).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }

}