import * as React from 'react';

const LocationIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 7.27273C12.5 7.65804 12.3698 8.3314 12.0466 9.18745C11.7329 10.0185 11.2743 10.9234 10.7096 11.7533C9.5064 13.5215 8.14783 14.5 7 14.5C5.85217 14.5 4.4936 13.5215 3.29037 11.7533C2.72567 10.9234 2.26714 10.0185 1.95342 9.18745C1.63024 8.3314 1.5 7.65804 1.5 7.27273C1.5 4.02999 4.01595 1.5 7 1.5C9.98405 1.5 12.5 4.02999 12.5 7.27273ZM14 7.27273C14 9.8348 10.866 16 7 16C3.13401 16 0 9.8348 0 7.27273C0 3.25611 3.13401 0 7 0C10.866 0 14 3.25611 14 7.27273ZM8.5 7C8.5 7.82843 7.82843 8.5 7 8.5C6.17157 8.5 5.5 7.82843 5.5 7C5.5 6.17157 6.17157 5.5 7 5.5C7.82843 5.5 8.5 6.17157 8.5 7ZM10 7C10 8.65685 8.65685 10 7 10C5.34315 10 4 8.65685 4 7C4 5.34315 5.34315 4 7 4C8.65685 4 10 5.34315 10 7Z" 
        fill="#001A1A"
      />
    </svg>
  )
}

export default LocationIcon;
