// utils/FilterUtils.ts
export class DescriptionBuilderUtils {
    static filterCatalogAttributes(post: any): any[] | undefined {
        const allowedKeys = [
            "sk_yacht_type",
            "sk_bedrooms",
            "sk_number_of_guests",
            "sk_beds",
            "sk_badthrooms",
            "sk_room_amount",
            "sk_living_space",
            "sk_living_space_web",
            "sk_flats_place_type",
            "sk_bathrooms",
            "sk_catalog_amenities_type",
        ];

        return post?.post_meta?.catalog_attributes?.filter((attribute: any) =>
            allowedKeys.includes(attribute.search_key)
        );
    }
}
