import {RailData} from "@domain/models/rail/RailData";
import RailViewModel from "framework/viewmodels/RailViewModel";
import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import CardView from "../CardView/CardView";

import "./Rail.scss";

export interface RailComponentProps {
    key: string | number;
    railData: RailData;
    railViewModel: RailViewModel;
}

const Rail: React.FC<RailComponentProps> = observer(
    ({railData, railViewModel}: RailComponentProps): JSX.Element => {
        const {t} = useTranslation();

        useEffect(() => {
            railViewModel.getRail(railData.rail_id);
        }, [railData]);

        return (
            <div className="rail-container">
                <h4 className="rail-title">
                    {t(railData.railTitle ? railData.railTitle : "")}
                </h4>

                <div className="rail-content">
                  <Row
                    className="rail gy-2 gx-0 pb-5"
                    xs={1}
                    sm={2}
                    md={3}
                    xl={4}
                    xxl={5}
                  >
                      {railViewModel?.isLoadingRequest
                          ? Array.from(new Array(6)).map((it, index) => {
                              return (
                                  <Col
                                      key={`loading-column-${index}`}
                                  >
                                      {" "}
                                      <CardView isLoading={true}/>{" "}
                                  </Col>
                              );
                          })
                          : railViewModel.getRailData()?.posts?.map((post) => {
                              return (
                                  <Col
                                      key={`post-id-${post.id}`}
                                      className="rail__item"
                                  >
                                      {" "}
                                      <CardView
                                          post={post}
                                          id={railData.rail_id}
                                          isLoading={railViewModel?.isLoadingRequest}
                                      />
                                  </Col>
                              );
                          })}
                  </Row>
                </div>
            </div>
        );
    }
);

export default Rail;
