import React, {useEffect, useState} from "react";
import {Button, Card, Container, Form, Modal, Spinner} from "react-bootstrap";
import UserViewModel from "@viewmodels/UserViewModel";
import {observer} from "mobx-react";
import {UserV1} from "@domain/models/user/UserV1";
import FirebaseApp from "../../../../../firebaseApp";
import {useTranslation} from "react-i18next";
import R from "@root/framework/res/strings/R";
import {useInjection} from "@root/ioc.react";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import TYPES from "@di/viewmodels/ViewModelTypes";
import {useSaveAccountDetailsViewModel} from "@root/framework/hooks/useSaveUserDetailsViewModel";
import SaveUserAccountUseCase from "@domain/usecases/user/SaveUserAccountUseCase";
import ValidationMessage from "../../components/Form/ValidationMessage/ValidationMessage";

import "./MyDetails.scss";

enum FormFields {
  FullName = 'full_name',
  EnableNotifications = 'enable_notifications',
  PhoneNumberCode = 'phone_number_code',
  PhoneNumber = 'phone_number'
}

export interface MyDetailsProps {
  userViewModel: UserViewModel
}

interface ValidatableFields {
  [FormFields.PhoneNumberCode]: string;
}

const MyDetails: React.FC<MyDetailsProps> = observer(({userViewModel}: MyDetailsProps) => {
    const saveCurrentLocationUseCase = useInjection<IProvider<SaveUserAccountUseCase>>(TYPES.SaveUserAccountUseCase).provide()

    const {t} = useTranslation();

    const [editableDetails, setEditableDetails] = useState({
      [FormFields.FullName]: '',
      [FormFields.PhoneNumberCode]: '',
      [FormFields.PhoneNumber]: '',
      [FormFields.EnableNotifications]: false,
    });

    const defaultValidationErrors: ValidatableFields = {
      [FormFields.PhoneNumberCode]: ''
    }

    const [validationErrors, setValidationErrors] = useState<ValidatableFields>(defaultValidationErrors);

    const {stateSaveUserUseCase, saveUserDetails} = useSaveAccountDetailsViewModel(saveCurrentLocationUseCase);

    useEffect(() => {
        if (stateSaveUserUseCase.result) {
          // Show success dialog if there is no error
          // Assuming you have a function to show the success dialog
          alert("User saved successfully!");
        } else if (stateSaveUserUseCase.error) {
          // Show error dialog if an error is present
          // Assuming you have a function to show the error dialog
          alert("An error occurred while saving the user");
        }
    }, [stateSaveUserUseCase]);


    useEffect(() => {
      userViewModel.handleGetMyUserAccount()
    }, []);

    useEffect(() => {
      const userAccount: UserV1 | undefined = userViewModel.getUserData

      setEditableDetails({
        [FormFields.FullName]: userAccount?.full_name!!,      // Replace with the desired name
        [FormFields.PhoneNumberCode]: userAccount?.phone_number_code!!,    // Replace with the desired phone number code
        [FormFields.PhoneNumber]: userAccount?.phone_number!!, // Replace with the desired phone number
        [FormFields.EnableNotifications]: userAccount?.enable_notifications!!, // Set directly to true
      });
    }, [userViewModel.getUserData]);

    const email = FirebaseApp.getInstance()?.getFirebaseAuth()?.currentUser?.email;

    const getValidatableFields = () => {
      return Object.assign({}, defaultValidationErrors);
  }

    const handleValidation = (field: FormFields, value: any) => {
      const fieldErrors = getValidatableFields();

      switch(field) {
        case FormFields.PhoneNumberCode:
          if (value.length > 5) {
            fieldErrors[FormFields.PhoneNumberCode] = 'Country code cannot be longer than 5 characters.';
          }
      }

      if (Object.values(fieldErrors).some(val => val.length > 0)) {
        setValidationErrors(fieldErrors);
        return;
      }

      setValidationErrors(defaultValidationErrors);
    }

    const handleInputChange = (field: FormFields, value: any) => {
      handleValidation(field, value);

      setEditableDetails((prev) => ({
        ...prev,
        [field]: value,
      }));
    };

    const handleSave = () => {
      if (!userViewModel.isLoadingRequest) {
        saveUserDetails({
          [FormFields.FullName]: editableDetails.full_name,
          [FormFields.PhoneNumberCode]: editableDetails.phone_number_code,
          [FormFields.PhoneNumber]: editableDetails.phone_number,
          [FormFields.EnableNotifications]: editableDetails.enable_notifications,
        });
      }
    };

    return (
        <Container className="user-details d-flex justify-content-center align-items-center vh-80">
            <Card className="user-details__card">
                <Card.Body>
                    {userViewModel.isLoadingRequest ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <Form>
                            {email && <p className="user-details__email">{email}</p>}

                            <Form.Group controlId="fullName">
                                <Form.Label className="user-details__section-title">{t(R.userName)}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter full name"
                                    value={editableDetails.full_name}
                                    onChange={(e) => handleInputChange(FormFields.FullName, e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="enableNotifications" className="mt-3">
                                <Form.Label
                                    className="user-details__section-title">{t(R.enableNotificationOnApp)}</Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="enable-notifications-switch"
                                    label={editableDetails.enable_notifications ? 'Enabled' : 'Disabled'}
                                    checked={editableDetails.enable_notifications}
                                    onChange={(e) =>
                                      handleInputChange(FormFields.EnableNotifications, e.target.checked)
                                    }
                                    className="user-details__switch"
                                />
                            </Form.Group>

                            <Form.Group controlId="phoneNumberCode" className="mt-3">
                                <Form.Label
                                    className="user-details__section-title">{t(R.codeContactNumber)}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter phone number code"
                                    value={editableDetails.phone_number_code}
                                    onChange={(e) => handleInputChange(FormFields.PhoneNumberCode, e.target.value)}
                                />
                                {validationErrors[FormFields.PhoneNumberCode] && (
                                  <ValidationMessage text={validationErrors[FormFields.PhoneNumberCode]} />
                                )}
                            </Form.Group>

                            <Form.Group controlId="phoneNumber" className="mt-3">
                                <Form.Label className="user-details__section-title">{t(R.contactNumber)}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter phone number"
                                    value={editableDetails.phone_number}
                                    onChange={(e) => handleInputChange(FormFields.PhoneNumber, e.target.value)}
                                />
                            </Form.Group>

                            <Button variant="primary" role="button" className="user-details__button mt-4" onClick={handleSave}>
                                {t(R.editContactDetails)}
                            </Button>
                        </Form>
                    )}
                </Card.Body>
            </Card>
        </Container>)
});

export default MyDetails;
