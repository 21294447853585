import { PostResultData } from "@domain/models/post/PostResponseV1";
import { MIN_SEARCH_DEBOUNCE_TIME_MS } from "@utils/Constants";
import SearchRepository from "../../repository/search/ISearchRepository";

export default class SearchUseCase {

    private searchRepository: SearchRepository
    private   delayDebounceFn : NodeJS.Timeout| undefined = undefined

    public constructor(searchRepository: SearchRepository) {
        this.searchRepository = searchRepository
    }

    /**
     * Seach post suggestion list
     * @param paramsMap
     * @throws {Error} in case of getting non 2xx response or empty query 
     */
    public async searchPostSuggestions(paramsMap:  Map<string, string> ) :  Promise<PostResultData | undefined> {
        return new Promise((resolve, reject) => {
            if(this.delayDebounceFn){
                clearTimeout(this.delayDebounceFn);
              }
            this.delayDebounceFn = setTimeout(async () => {
              await this.searchRepository.getPostSuggestions(paramsMap).then(response => {
                  resolve(response)
              }).catch(error => {
                  reject(error)
              })
            }, MIN_SEARCH_DEBOUNCE_TIME_MS)
            
          
        })
    }

    /**
     * Seach post result list
     * @param paramsMap
     * @param categoryId id that indicate the category_id
     * @throws {Error} in case of getting non 2xx response or empty query
     */
     public async searchPostResult(categoryId:string,paramsMap:  Map<string, string> ) :  Promise<PostResultData | undefined> {
        return new Promise((resolve, reject) => {
            if(this.delayDebounceFn){
                clearTimeout(this.delayDebounceFn);
              }
             this.delayDebounceFn = setTimeout(async () => {
               await this.searchRepository.getPostSearchResults(categoryId, paramsMap).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            }, MIN_SEARCH_DEBOUNCE_TIME_MS)
            
          
        })
    }

}