import getCatalogConfig from "@utils/remote_config/CatalogConfig";
import {Catalog, CatalogModel} from "@domain/models/catalog/CatalogModel";

export const getCurrentCatalog = (): Catalog | undefined => {
  const catalogId = 'CAT-1';
  let catalogConfig: CatalogModel | undefined = getCatalogConfig();

  return catalogConfig?.catalog.find(ct => {
    if (ct.id === catalogId) {
        return ct;
    }
  });
};
