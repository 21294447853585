import React from "react";
import { Modal, Carousel } from "react-bootstrap";
import { PostData, Image } from "@domain/models/post/PostResponseV1";
import CameraIcon from '../Icons/CameraIcon';

import "./Gallery.scss";

interface GalleryProps {
  post: PostData;
  showGallery?: boolean;
  showCarousel?: boolean;
  carouselImageIndex?: number;
  handleCloseGallery?(): void;
  handleOpenCarousel?(index: number): void;
  handleCloseCarousel?(): void;
  handleCarouselSelect?(index: number): void;
}

const renderCarousel = ({
  post,
  carouselImageIndex,
  handleCloseCarousel,
  handleCarouselSelect
}: GalleryProps) => {
  return (
    <div className="gallery-carousel">
      <Modal show={true} fullscreen={true} animation={false} onHide={handleCloseCarousel} contentClassName="gallery-carousel-modal">
        <Modal.Header closeButton>
          {/* <Modal.Title>{post.title}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Carousel 
            activeIndex={carouselImageIndex}
            onSelect={handleCarouselSelect}
            slide={false}
            interval={null}
            indicators={false}
            nextIcon={
              post.images.length > 1 && (
                <span
                  aria-hidden="true"
                  className="gallery-control-next-icon"
                />
              )
            }
            prevIcon={
              post.images.length > 1 && (
                <span
                  aria-hidden="true"
                  className="gallery-control-prev-icon"
                />
              )
            }
          >
            {post.images.map((image: Image, index: number) => {
              return (
                <Carousel.Item key={image.id}>
                  <img className="gallery-carousel__image d-block mx-auto" src={image.medium} role="button"/>

                  <div className="gallery-carousel__meta">
                    <span className="gallery-carousel__meta__icon">
                      <CameraIcon />
                    </span>

                    <span className="gallery-carousel__meta__total">
                      {index + 1}/{post.images.length}
                    </span>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const renderGallery = ({ post, handleCloseGallery, handleOpenCarousel }: GalleryProps) => {
  return (
    <Modal show={true} onHide={handleCloseGallery} fullscreen={true} animation={false}>
      <Modal.Header closeButton>
        {/* <Modal.Title>{post.title}</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="gallery">
          {post?.images?.map((image: Image, index: number) => {
            return (
                <div key={image.id} className="gallery__item" onClick={() => handleOpenCarousel?.(index)}>
                  <div className="gallery__item__inner">
                    <img className="gallery__image" src={image.medium} />
                  </div>
                </div>
            )
          })};
        </div>
      </Modal.Body>
    </Modal>
  )
}

export const Gallery: React.FC<GalleryProps> = ({
  post,
  showGallery,
  showCarousel,
  carouselImageIndex,
  handleCloseGallery,
  handleOpenCarousel,
  handleCloseCarousel,
  handleCarouselSelect
}: GalleryProps): JSX.Element => {

  if (showGallery) {
    return renderGallery({ post, handleCloseGallery, handleOpenCarousel });
  }

  if (showCarousel) {
    return renderCarousel({ post, carouselImageIndex, handleCloseCarousel, handleCarouselSelect });
  }

  return (
    <div></div>
  );
};
