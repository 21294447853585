import {SearchKeys} from "@enums/SearchKeys";

class URLUtils {
    static getParamsMap(search: string): Map<string, string> {
        const urlParams = new URLSearchParams(search);

        const paramsMap = new Map<string, string>();
        urlParams.forEach((value, key) => {
            paramsMap.set(key, value);
        });

        return paramsMap;
    }

    static getCountFilter(search: string) {
        const paramsMap = URLUtils.getParamsMap(search)

        const keysToExclude = new Set<string>([
            SearchKeys.SEARCH_DISTANCE_UNIT_SEARCH_KEY,
            SearchKeys.PERIOD_SEARCH_KEY,
            SearchKeys.QUERY_SEARCH_KEY,
            SearchKeys.PLACE_SEARCH_KEY
        ]);

        const otherKeys = new Map<string, string>(
            Array.from(paramsMap.entries()).filter(([key]) => !keysToExclude.has(key))
        );

        return otherKeys.size
    }
}

export default URLUtils;