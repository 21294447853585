import React from "react";
import RailGroup from "@presentation/views/components/RailGroup/RailGroup";
import AboutUs from "@presentation/views/components/AboutUs/AboutUs";
import SearchViewModel from "@viewmodels/SearchViewModel";
import { SearchContainer } from "@presentation/views/components/Search/SearchContainer/SearchContainer";

export interface HomeProps {
    searchViewModel: SearchViewModel;
}

export function Home({searchViewModel}: HomeProps): React.ReactElement {
    return (
        <>
            <SearchContainer searchViewModel={searchViewModel}/>
            <RailGroup/>
            <AboutUs/>
        </>
    );
}
