import * as React from 'react';

const Logo = () => {
  return (
    <svg width="37" height="26" viewBox="0 0 37 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.6274 0.386719L23.0424 6.69386L18.6274 13.001L14.2124 6.69386L18.6274 0.386719Z" fill="#1C5AD6"/>
      <path d="M27.457 0.386719L31.872 6.69386L27.457 13.001L23.042 6.69386L27.457 0.386719Z" fill="#3D79F2"/>
      <path d="M31.872 6.69336L36.287 13.0005L31.872 19.3077L27.457 13.0005L31.872 6.69336Z" fill="#3D79F2"/>
      <path d="M27.457 13.002L31.872 19.3091L27.457 25.6162L23.042 19.3091L27.457 13.002Z" fill="#3D79F2"/>
      <path d="M18.6274 13.002L23.0424 19.3091L18.6274 25.6162L14.2124 19.3091L18.6274 13.002Z" fill="#3D79F2"/>
      <path d="M14.2124 6.69336L18.6274 13.0005L14.2124 19.3077L9.79736 13.0005L14.2124 6.69336Z" fill="#3D79F2"/>
      <path d="M9.79733 13.002L14.2123 19.3091L9.79733 25.6162L5.38232 19.3091L9.79733 13.002Z" fill="#1C5AD6"/>
      <path d="M5.38229 19.3086L9.79729 25.6157H0.967285L5.38229 19.3086Z" fill="#3D79F2"/>
      <path d="M14.2124 19.3086L18.6274 25.6157H9.79736L14.2124 19.3086Z" fill="#1C5AD6"/>
      <path d="M31.872 19.3086L36.287 25.6157H27.457L31.872 19.3086Z" fill="#1C5AD6"/>
      <path d="M23.0424 6.69336L27.4574 0.386213H18.6274L23.0424 6.69336Z" fill="#3D79F2"/>
      <path d="M14.2124 6.69336L18.6274 0.386213H9.79736L14.2124 6.69336Z" fill="#1C5AD6"/>
      <path d="M9.79733 13.002L14.2123 6.69481H5.38232L9.79733 13.002Z" fill="#3D79F2"/>
      <path d="M5.38229 19.3086L9.79729 13.0014H0.967285L5.38229 19.3086Z" fill="#1C5AD6"/>
    </svg>
  )
};

export default Logo;
