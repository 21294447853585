import {useCallback, useState} from "react";
import {UserV1} from "@domain/models/user/UserV1";
import GetUserAccountUseCase from "@domain/usecases/user/GetUserAccountUseCase";

export interface UserAccountViewStateViewState {
    userAccount?: UserV1
    isLoading?: boolean;
    error?: string | null;
}

export const useGetOtherUserAccountViewModel = (getUserAccountUseCase: GetUserAccountUseCase) => {
    const [stateGetUserAccount, setStateGetUserAccount] = useState<UserAccountViewStateViewState>({isLoading: false});

    const getOtherUserAccount = useCallback((userId: number) => {
        setStateGetUserAccount({isLoading: true});
        getUserAccountUseCase.getOtherUserAccount(userId).then(result => {
                setStateGetUserAccount({userAccount: result, isLoading: false, error: null});
            }
        ).catch(error => {
            setStateGetUserAccount({error: 'Error getting another user account', isLoading: false});
        });


    }, [getUserAccountUseCase]);

    return {
        stateGetUserAccount: stateGetUserAccount,
        getOtherUserAccount: getOtherUserAccount
    };
};