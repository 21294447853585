import * as React from 'react';

import "./Button.scss";

interface ButtonProps {
  children: string;
  type: 'primary' | 'secondary';
  to?: string;
  otherClassName?: string;
  onClick?: () => any;
}

const Button = ({ type, children, to, otherClassName, onClick }: ButtonProps) => {
  let className = `${type}`;

  if (otherClassName) {
    className += ` ${otherClassName}`;
  }

  if (to) {
    return (
      <a
        href={to}
        className={`btn ${className}`}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    )
  }

  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>)
};

export default Button;
