import {PostData} from "@domain/models/post/PostResponseV1";
import React from "react";
import {Carousel} from "react-bootstrap";
import "./Slider.scss";
import camera from "@assets/camera.svg";
import brokenImage from "@assets/link_broken.jpg";
import {useNavigate} from 'react-router-dom';


export interface CardComponentProps {
    id: string;
    post: PostData;
    borderClass?: string;
}

const Slider: React.FC<CardComponentProps> = ({
  post,
  id,
  borderClass,
}: CardComponentProps): JSX.Element => {
    const navigate = useNavigate();

    return (
        <Carousel
            slide={false}
            interval={null}
            indicators={false}
            nextIcon={
                post?.images?.length > 1 && (
                    <span
                        aria-hidden="true"
                        className="carousel-control-next-icon"
                    />
                )
            }
            prevIcon={
                post?.images?.length > 1 && (
                    <span
                        aria-hidden="true"
                        className="carousel-control-prev-icon"
                    />
                )
            }
        >
            {post?.images?.map((image, index) => {

                const urlImg = image.medium;

                return (
                    <Carousel.Item
                        role="button"
                        onClick={(even: React.MouseEvent<HTMLDivElement>) => {
                            if (post) {
                                navigate('/all/details/' + post.id)
                            }
                        }}
                    >
                      <div className="slider-image-container">
                        <img
                          className={`slider-image d-block img_rounded-border ${borderClass}`}
                          src={urlImg}
                          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            e.currentTarget.src = brokenImage;
                          }}
                        />

                        <div className="item-multimedia-pictures">
                          {/* <span className="camera-style"></span> */}
                          <img src={camera} className="camera-style" />
                          <span>
                            {index + 1}/{post.images.length}
                          </span>
                        </div>
                      </div>
                    </Carousel.Item>
                );
            })}
        </Carousel>
    );
};

export default Slider;
