export enum Path {
    ROOT = '/home',

    HOME = '/',

    SEARCH = '/search',

    SEARCH_DETAILS = '/details',

    POST_DETAILS = ':category/details/:id',

    PRIVACY = '/privacy',

    TERMS = '/terms-of-service',

    HOW = '/how-it-works',

    FAQS = '/faqs',

    LOGIN = '/login',

    POSTS = '/posts',

    OTHER_POSTS = 'posts/others/:user-id',


    // CREATE_POST = '/posts/create',

    // SAVED = '/saved',

    // FAVOURITES = '/favorites',

    MY_DETAILS = '/details',

    NOT_FOUND = '*',

}


export const paths: string[] = Object.values(Path);

export const isPath = (url: Path | string): boolean => paths.includes(url);