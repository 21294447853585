import React, {useEffect, useState} from "react";

import FirebaseApp from "../../../../../firebaseApp";
import AuthNavigation from "@presentation/views/components/AuthNavigation/AuthNavigation";
import UserViewModel from "@viewmodels/UserViewModel";
import {observer} from "mobx-react";

interface SignInRegisterProps {
    userViewModel: UserViewModel
}

export const SignInRegister: React.FC<SignInRegisterProps> = observer(({userViewModel}: SignInRegisterProps): JSX.Element => {
    const [isSignedIn, setIsSignedIn] = useState(false);


    useEffect(() => {
        // Set up Firebase authentication state observer
        const unsubscribe = FirebaseApp.getInstance().getFirebaseAuth().onAuthStateChanged(async (authUser) => {

            if (authUser) {
                setIsSignedIn(true)
                const idToken = await authUser.getIdToken();
                userViewModel.handleGetUserToken(idToken)
            } else {
                setIsSignedIn(false)
                userViewModel.signOut()

            }

        });

        // Cleanup the observer on unmount
        return () => unsubscribe();
    }, []);


    return <>
        <AuthNavigation isSignedIn={isSignedIn}/>
    </>
})