import axios, { AxiosRequestConfig } from 'axios';
import {TOKEN_STORE_KEY} from "@utils/Constants";

// Create an Axios instance
const api = axios.create();

// Add an interceptor to include the User-Agent in every request
api.interceptors.request.use(
    (config) => {
         config.headers['X-User-Agent'] = `RentalloWeb/1.0`;
         config.headers['Content-Type'] = 'application/json';

        const token = localStorage.getItem(TOKEN_STORE_KEY);
         if (token) {
            config.headers['Authorization'] = `${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;