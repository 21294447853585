

let TYPES = {
    CatalogViewModel: Symbol("CatalogViewModel"),
    PostViewModel: Symbol("PostViewModel"),
    RailViewModel: Symbol("RailViewModel"),
    SearchViewModel: Symbol("SearchViewModel"),
    UserViewModel: Symbol("UserViewModel"),

    //Use cases
    GetPostDetailsUseCase: Symbol("GetPostDetailsUseCase"),
    GetMyPostsUseCase: Symbol("GetMyPostsUseCase"),
    DeleteMyPostUseCase: Symbol("DeleteMyPostUseCase"),
    GetMyFavouritesUseCase: Symbol("GetMyFavouritesUseCase"),
    CreatePostUseCase: Symbol("CreatePostUseCase"),
    DeleteFavouriteUseCase: Symbol("DeleteFavouriteUseCase"),

    GetUserAccountUseCase: Symbol("GetUserAccountUseCase"),
    SaveUserAccountUseCase: Symbol("SaveUserAccountUseCase"),

    GetTokenUseCase: Symbol("GetTokenUseCase"),
    SignOutUseCase: Symbol("SignOutUseCase"),

    SearchUseCase: Symbol("SearchUseCase"),
    SearchLocationUseCase: Symbol("SearchLocationUseCase"),
    GetMySavedSearchesUseCase: Symbol("GetMySavedSearchesUseCase"),
    SearchRecommendationsUseCase: Symbol("SearchRecommendationsUseCase"),
    GetPostsInHistoryUseCase: Symbol("GetPostsInHistoryUseCase"),
    DeletePostFromHistoryUseCase: Symbol("DeletePostFromHistoryUseCase"),
    SavePostInHistoryUseCase: Symbol("SavePostInHistoryUseCase"),
    GetCurrentLocationUseCase: Symbol("GetCurrentLocationUseCase"),
    SaveCurrentLocationUseCase: Symbol("SaveCurrentLocationUseCase"),
    GetOtherPostsUseCase: Symbol("GetOtherPostsUseCase"),
};

export default TYPES;


