import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import { Row } from "react-bootstrap";

import CardView from "@presentation/views/components/CardView";
import EmptyListMessage from "@presentation/views/components/EmptyListMessage/EmptyListMessage";
import SearchViewModel from "@viewmodels/SearchViewModel";
import { PostResultData } from "@domain/models/post/PostResponseV1";
import { Path } from "@enums/Path";

import "./SearchResultsList.scss";

interface SearchResultsListProps {
  searchResults: PostResultData | null | undefined;
  searchViewModel: SearchViewModel;
}

const SearchResultsList = ({ searchResults, searchViewModel }: SearchResultsListProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClickClearAllFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const searchParams = new URLSearchParams();

    const q = new URLSearchParams(location.search).get('q');
    const lat = new URLSearchParams(location.search).get('lat');
    const lon = new URLSearchParams(location.search).get('lon');

    if (q) searchParams.set('q', q);
    if (lat) searchParams.set('lat', lat);
    if (lon) searchParams.set('lon', lon);

    navigate(`${Path.SEARCH}?${searchParams.toString()}`, { replace: true });
  };

  const list = searchResults?.meta.total !== 0
    ? (
      <Row className="mb-2" xs={1} sm={2} lg={3}>
          {
            searchResults?.data?.map((post) => (
              <div className="custom-sm custom-md custom-lg" key={post.id}>
                <CardView
                  isLoading={searchViewModel.isLoadingRequest}
                  post={post}
                  id={post.post_meta?.catalog_id}
                />
              </div>
            ))
          }
      </Row>)
    : <EmptyListMessage onClickEmpty={onClickClearAllFilters}/>

  return (
    <div className="search-results-list">
      {list}
    </div>
  )
};

export default SearchResultsList;
