import React, {useState, ReactNode, useEffect, useRef, useImperativeHandle, forwardRef} from "react";
import {Sheet} from "react-modal-sheet";
import "./BottomSheetModalCustom.scss"

interface BottomSheetModalCustomProps {
    headerContent?: ReactNode;
    children?: ReactNode;
}

// Define a type for the ref object methods
export interface BottomSheetModalCustomHandles {
    open: () => void;
    close: () => void;
}

const BottomSheetModalCustom = forwardRef<BottomSheetModalCustomHandles, BottomSheetModalCustomProps>(
    ({headerContent, children}, ref) => {

        const [isOpen, setOpen] = useState(false);
        const sheetRef = useRef<HTMLDivElement>(null); // Create a ref to the Sheet container
        const screenHeight = window.innerHeight;
        const snapPoints = [screenHeight * 0.5, screenHeight * 0.50]; // 50% and 25% of screen height

        // Expose open and close methods to the parent component
        useImperativeHandle(ref, () => ({
            open: () => setOpen(true),
            close: () => setOpen(false),
        }));



        useEffect(() => {
            // Handler to close the modal if a click is detected outside of it
            const handleClickOutside = (event: MouseEvent) => {

                if (sheetRef.current && !sheetRef.current.contains(event.target as Node)) {
                    setOpen(false);
                 }
            };

            if (isOpen) {
                // Disable background scrolling when the sheet is open
                document.body.classList.add("bottom-sheet-open");
                document.addEventListener('mousedown', handleClickOutside);

            }else {
                document.body.classList.remove("bottom-sheet-open");
            }

            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [isOpen]);

        return (
            <>
                <div
                    onClick={() => setOpen(true)}>{headerContent}
                </div>

                <Sheet
                    isOpen={isOpen}
                    onClose={() => setOpen(false)}
                    snapPoints={snapPoints}
                    initialSnap={1}
                >
                    <Sheet.Container ref={sheetRef}>
                        <Sheet.Header>
                        </Sheet.Header>
                        <Sheet.Content>
                            {children}
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop/>
                </Sheet>
            </>
        );
    });

export default BottomSheetModalCustom;
