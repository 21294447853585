export enum PricePerTypes {
	DAY = 'day',
    WEEK="week",
    MONTH='month',
    YEAR="year"
}

export const PriceKeyword: Record<string, string> = {
    ["year"]:"Yearly",
    ["day"]:"pcd",
    ["week"] : "pcw",
    ["month"] :"pcm"
  }