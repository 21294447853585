import React, {useEffect, useState} from 'react';
import './SearchMobile.scss';

import StickySearchIcon from '../../../Icons/StickySearchIcon';
import SearchMobileDialog
    from "@presentation/views/components/Search/SearchExperience/SearchMobileDialog/SearchMobileDialog";
import {useTranslation} from "react-i18next";
import R from "@strings/R"
import {useLocation} from "react-router-dom";
import UrlUtils from "@utils/UrlUtils";
import {SearchKeys} from "@enums/SearchKeys";

const SearchMobile: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentSearchQuery, setCurrentSearchQuery] = useState<string | undefined>(undefined)
    const {t} = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        const query = paramsMap.get(SearchKeys.QUERY_SEARCH_KEY)
        setCurrentSearchQuery(query)
    }, [location]);

    const toggleDialog = () => {
        setIsOpen(!isOpen);
    };


    return (
        <>
            {/* Search Input that opens the dialog */}
            <div className="search-mobile__search-input-container">
                <StickySearchIcon/>
                <input
                    type="text"
                    placeholder={t(R.landingPage_search_hint)}
                    onFocus={toggleDialog}
                    value={currentSearchQuery}
                    className="search-mobile__search-input"
                />
            </div>

            {isOpen ? <SearchMobileDialog hide={() => toggleDialog()}/> : null}
        </>)
};

export default SearchMobile;
