import React, {ChangeEvent, useEffect} from "react";
import FactoryMetaFilter from "@utils/factories/FactoryMetaFilter";
import MetaSearchUtils from "@utils/MetaSearchUtils";
import FilterRequestBuilder from "@utils/filter/FilterRequestBuilder";
import SearchViewModel from "@viewmodels/SearchViewModel";
import {FilterInputTypes} from "@enums/FilterInputTypes";
import {SortByKeys} from "@enums/SortByKeys";

interface SortResultsListFilterProps {
    searchViewModel: SearchViewModel;
}

const SortResultsListFilter = ({searchViewModel}: SortResultsListFilterProps) => {
    const filter = FactoryMetaFilter.createFixMetaFilter(
        FilterInputTypes.SORT_RESULT_LIST,
        searchViewModel,
        MetaSearchUtils.getSortsRadiusAttribute(),
        (event: ChangeEvent<HTMLSelectElement>) => {
            FilterRequestBuilder.getInstance().addPriceFilterOrder(event.target.value as SortByKeys)
        }
    );


    if (!filter) {
        return null;
    }

    return filter;
};

export default SortResultsListFilter;
