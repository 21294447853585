import React, {ChangeEvent, useEffect, useRef, useState} from "react"
import {Col, Container, Row} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import R from "@strings/R"
import {Attribute, Value} from "@domain/models/catalog/CatalogModel"
import "./PriceFilter.scss"
import UrlUtils from "@utils/UrlUtils";
import {SearchKeys} from "@enums/SearchKeys";
import {useLocation, useNavigate} from "react-router-dom";
import NavigationUtils from "@utils/NavigationUtils";
import FilterRequestBuilder from "@utils/filter/FilterRequestBuilder";
import ValidationMessage from "../../Form/ValidationMessage/ValidationMessage"


interface InputListRange {
    attribute: Attribute
}

export function PriceFilter({attribute}: InputListRange): React.ReactElement {
    const [minPrice, setMinPrice] = useState("")
    const [maxPrice, setMaxPrice] = useState("")
    const navigate = useNavigate()
    const [validationError, setValidationError] = useState<string | null>(null);

    const {t} = useTranslation()

    const location = useLocation();

    useEffect(() => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        const minPrice = FilterRequestBuilder.getInstance()?.getInitialValue(paramsMap, SearchKeys.MIN_PRICE_SEARCH_KEY)
        const maxPrice = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, SearchKeys.MAX_PRICE_SEARCH_KEY)

        if (minPrice != undefined) {
            setMinPrice(minPrice)
        }
        if (maxPrice != undefined) {
            setMaxPrice(maxPrice)
        }
    }, [])


    useEffect(() => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        const minPrice = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, SearchKeys.MIN_PRICE_SEARCH_KEY)
        const maxPrice = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, SearchKeys.MAX_PRICE_SEARCH_KEY)

        if (!minPrice && minPrice != "") {
            setMinPrice("")
        }
        if (!maxPrice && maxPrice != "") {
            setMaxPrice("")
        }


    }, [location])


    function navigateTo(key: string, e: React.ChangeEvent<HTMLInputElement>) {
        const updatedSearchParams = NavigationUtils.buildSearchParams({
            [key]: e.target.value,
        });

        navigate(updatedSearchParams, {replace: true});
    }

    const handleMinPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMinPrice(e.target.value);
        if (handleValidationError(e.target.value, maxPrice)) {
            navigateTo(SearchKeys.MIN_PRICE_SEARCH_KEY, e);
        }
    };

    const handleMaxPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMaxPrice(e.target.value);
        if (handleValidationError(minPrice, e.target.value)) {
            navigateTo(SearchKeys.MAX_PRICE_SEARCH_KEY, e);
        }
    };
    const handleValidationError = (minPrice: string, maxPrice: string) => {
        // Helper function to check if a string represents a positive integer
        const isPositiveInteger = (value: string) => {
            if(value == "")
                return true
            const number = Number(value);
            return Number.isInteger(number) && number > 0;
        };

        // Validate minPrice
        if (!isPositiveInteger(minPrice)) {
            setValidationError(t(R.negativePriceInvalidError));
            return false;
        }

        // Validate maxPrice
        if (!isPositiveInteger(maxPrice)) {
            setValidationError(t(R.negativePriceInvalidError));
            return false;
        }

        const min = parseInt(minPrice, 10);
        const max = parseInt(maxPrice, 10);

        // Check if min is greater than max
        if (min > max) {
            setValidationError(t(R.minPriceError));
            return false;
        } else {
            setValidationError(''); // Clear error if valid
        }

        return true;
    };

    return (
        <Container fluid className="gx-0 mx-0" onClick={(e: React.MouseEvent<HTMLLIElement>) => {
        }}>
            <Row className="gx-0 mx-0" xs={2} md={2} lg={2}>
                <Col>
                    <input
                        value={minPrice}
                        type="number"
                        min="0"
                        className="price-input no-border "
                        placeholder={t(R.catalog_attribute_min_price)}
                        onChange={handleMinPriceChange}
                    />
                </Col>
                <Col>
                    <input
                        value={maxPrice}
                        type="number"
                        min="0"
                        className="price-input ms-1"
                        placeholder={t(R.catalog_attribute_max_price)}
                        onChange={handleMaxPriceChange}
                    />
                </Col>
            </Row>
            {validationError && (
              <ValidationMessage text={validationError} />
            )}
        </Container>
    )
}

export default PriceFilter