import React, {useMemo, useState} from "react";
import {Col, Image, Row} from "react-bootstrap";
import SearchViewModel from "@viewmodels/SearchViewModel";
import getImagesConfig from "@utils/remote_config/ImagesConfig";
import SearchText from "@presentation/views/components/Search/SearchText";
import SearchBarHomePage from "@presentation/views/components/Search/SearchBarHomePage/SearchBarHomePage";
import ContentBox from "../../ContentBox/ContentBox";
import StickySearch from "../StickySearch/StickySearch";
import useInView from "@root/framework/hooks/useInView";

import "./SearchContainer.scss";

export interface SearchComponentProps {
    searchViewModel: SearchViewModel;
}

export function SearchContainer({searchViewModel}: SearchComponentProps) {
    const [ref, isInView] = useInView();
// Memoize the landingImage to avoid unnecessary recalculations
    const landingImage = useMemo(() => {
        return getImagesConfig()?.landingImage;
    }, []); // Empty dependency array means this will run only once when the component mounts

    const onSearchInputClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [searchExperienceOpened, setSearchExperienceOpened] = useState(false);

    // Handle when the image is fully loaded
    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    const handleSearchExperienceOpen = (isOpened: boolean) => {
      setSearchExperienceOpened(isOpened);
    };

    return (
        <div className="search-container">
            {!isInView && <StickySearch/>}

            <div   style={{ display: isImageLoaded ? 'block' : 'none' }} key={"gradient_bg"} className="search-container__gradient"   />
            <Image
                key={"image_bg"}
                className="search-container__image"
                src={landingImage}
                alt=""
                style={{ display: isImageLoaded ? 'block' : 'none' }
            } // Show image only when loaded
                onLoad={handleImageLoad} // Set state to true when the image is loaded
            />

            <div className="search-container__content" ref={ref}>
                <ContentBox>
                    <div>
                        <Row>
                            <Col xl={4}>
                              {!searchExperienceOpened && <SearchText />}
                            </Col>
                            <Col xl={8} onClick={onSearchInputClick}>
                                <SearchBarHomePage handleSearchExperienceOpen={handleSearchExperienceOpen} />
                            </Col>
                        </Row>
                    </div>
                </ContentBox>
            </div>
        </div>
    );
}
