import React, {useState} from "react";

import SearchParagraph from "../SearchParagraph/SearchParagraph";
import SearchExperienceContainer
    from "@presentation/views/components/Search/SearchExperience/SearchExperienceContainer/SearchExperienceContainer";
import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";
import {useNavigate} from "react-router-dom";
import {Path} from "@enums/Path";
import {SearchInputGroup} from "@presentation/views/components/SearchInputGroup/SearchInputGroup";
import "./SearchBarHomePage.scss";
import SearchMobileDialog
    from "@presentation/views/components/Search/SearchExperience/SearchMobileDialog/SearchMobileDialog";
import {useIsTabletOrMobile} from "@utils/ScreenUtils";

export interface SearchComponentProps {
  handleSearchExperienceOpen(isOpened: boolean): void;
}

const SearchBarHomePage: React.FC<
    SearchComponentProps
> = ({ handleSearchExperienceOpen }: SearchComponentProps): JSX.Element => {
    const navigate = useNavigate();

    const [showSearchExperience, setShowSearchExperience] = useState(false);
    const [isSearchMobileDialogOpen, setIsSearchMobileDialogOpen] = useState(false);

    const toggleSearchMobileDialog = () => {
        setIsSearchMobileDialogOpen(!isSearchMobileDialogOpen);
    };

    const onHideSearchExperience = () => {
        setShowSearchExperience(false);
        handleSearchExperienceOpen(false);
    };

    const isTableOrMobileDevice = useIsTabletOrMobile()

    const onSearchInputClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (isTableOrMobileDevice) {
          setIsSearchMobileDialogOpen(true)
        } else {
          setShowSearchExperience(true);
          handleSearchExperienceOpen(true);
        }
    };

    function updateQueryParam(body: any) {
        const newParams: NavigationParams = body;
        const updatedSearchParams =
            NavigationUtils.buildSearchParams(newParams);
        navigate(Path.SEARCH + "/" + updatedSearchParams);
    }

    return (
      <div className="search-bar">
          { !showSearchExperience && (
            <div className="search-bar__search-input">
              <p className="search-bar__label">
                  <SearchParagraph/>
              </p>
            
              <SearchInputGroup onSearchInputClick={onSearchInputClick}/>
            </div>
          )}

          {
              !isTableOrMobileDevice && showSearchExperience ? (
                  <div className={"search-bar__search-experience-container-main"}>
                      <SearchExperienceContainer
                          updateQueryParam={updateQueryParam}
                          show={showSearchExperience}
                          onHide={onHideSearchExperience}
                      />
                  </div>
              ) : (
                  isSearchMobileDialogOpen ? <SearchMobileDialog hide={() => toggleSearchMobileDialog()}/> : null
              )
          }
        </div>
    );
};

export default SearchBarHomePage;
