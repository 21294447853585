
import PostViewModel from 'framework/viewmodels/PostViewModel';
import { useInjection } from '../../../../../ioc.react';
import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { IProvider } from '@di/viewmodels/ViewModelProvider';
import TYPES from '@di/viewmodels/ViewModelTypes';
import { observer } from 'mobx-react-lite';
import PostDetailsCardView from '@presentation/views/components/PostDetailsCardView';

interface PostDetailsProps {
	_postViewModel: PostViewModel
}

const PostDetails: React.FC = (): JSX.Element => {

	let { category, id } = useParams();
	const postViewModel = useInjection<IProvider<PostViewModel>>(TYPES.PostViewModel).provide();

	const PostDetailsView = observer(({ _postViewModel }: PostDetailsProps) => {
		return   <>
				{
						_postViewModel?.isLoadingRequest ? <Col><PostDetailsCardView isLoading={true}></PostDetailsCardView></Col>
							: _postViewModel.getPostData() ? <PostDetailsCardView isLoading={false} post={_postViewModel.getPostData()!!}></PostDetailsCardView> : null												
				}

			</>
	})

	useEffect(() => {
		if (id) {
			postViewModel.getPostDetails(id)
		}
	}, [])


	return <PostDetailsView _postViewModel={postViewModel} />;
}

export default PostDetails