import {Attribute, Value} from "@domain/models/catalog/CatalogModel";
import React, {useEffect, useRef, useState} from "react"
import {Col, Container, Form, Row} from "react-bootstrap"
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import "./InputChips.scss"
import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";
import UrlUtils from "@utils/UrlUtils";
import FilterRequestBuilder from "@utils/filter/FilterRequestBuilder";


interface InputCheckBoxProps {
    attribute: Attribute;
    type?: 'default' | 'price-of'; // Assuming you have a prop to determine the style type
    columns: number;

}

interface SelectedItem {
    value: string;
    qualifier?: string;
}

export function InputChips({attribute, type = 'default', columns}: InputCheckBoxProps): React.ReactElement {
    const {t} = useTranslation()

    const [selectedValue, setSelectedValue] = useState<SelectedItem>();
    const navigate = useNavigate()
    const location = useLocation();

    function getQualifierKey() {
        return attribute.search_key + "_qualifier";
    }

    const handleCheckboxChange = (e: React.MouseEvent<HTMLButtonElement>, value: Value) => {
        e.preventDefault();

        const selectedItem: SelectedItem = {
            value: value.value
        };

        const params: NavigationParams = {
            [attribute.search_key]: selectedItem.value,
        }

        if (value.qualifier !== undefined) {
            selectedItem.qualifier = value.qualifier;
            params[getQualifierKey()] = value.qualifier
        }


        const updatedSearchParams = NavigationUtils.buildSearchParams(params);

        navigate(updatedSearchParams, {replace: true});

        setSelectedValue(selectedItem)
    }


    useEffect(() => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
         const currentSelectedValue = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, attribute.search_key, attribute)
        const currentSelectedQualifier = FilterRequestBuilder.getInstance().getInitialValue(paramsMap, getQualifierKey())

        if (currentSelectedValue) {
            setSelectedValue({
                value: currentSelectedValue,
                qualifier: currentSelectedQualifier!!
            })
        } else {
            setSelectedValue({
                value: "",
                qualifier: ""
            })
        }
    }, [location])

    const btnClassName = `${type} input-chips__button `;

    return (
        <Container className="gx-0 mx-0" fluid>
            <Row   className={`gx-0 row-cols-${columns}`}>
                {attribute?.values?.map((at, index) => {
                    return (
                        <Col className={"mt-2"}>
                          <div className="input-chips">
                            <button
                              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleCheckboxChange(e, at)}
                              className={selectedValue?.value == at.value &&
                                selectedValue?.qualifier == at.qualifier ? btnClassName + "selected" : btnClassName}>
                                  {t(at.name_dict_key!!)}
                            </button>
                          </div>
                        </Col>
                    );
                })}
            </Row>
        </Container>
    );
}

export default InputChips