import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { fetchAndActivate, getAll, Value } from "firebase/remote-config";
import FirebaseApp from "./firebaseApp";
import rcDefaultsJson from "./framework/res/json/remote_config_defaults.json";

import "./index.scss";

const firebaseInstance = FirebaseApp.getInstance();


const remoteConfig = firebaseInstance.getRemoteConfig();

// remoteConfig.settings.minimumFetchIntervalMillis = 1000

remoteConfig.defaultConfig = rcDefaultsJson as any;

const rootElement = document.getElementById("root") as Element;
const root = createRoot(rootElement);

fetchAndActivate(remoteConfig)
    .finally(() => {
        root.render(<App />);
    });
