import React, {useEffect, useRef, useState} from "react";
import arrowBack from "@assets/arrow_back_ic.svg";
import R from "@strings/R";
import CatalogUtils from "@utils/CatalogUtils";
import ScrollableButtonGroup
    from "@presentation/views/components/Search/SearchExperience/ScrollableButtonGroup/ScrollableButtonGroup";
import Cities from "@presentation/views/components/Search/SearchExperience/Cities/Cities";
import {useSearchLocationViewModel} from "@root/framework/hooks/useSearchLocationViewModel";
import {useSaveCurrentLocationViewModel} from "@root/framework/hooks/useSaveCurrentLocationViewModel";
import {useSearchViewModel} from "@root/framework/hooks/useSearchViewModel";
import UrlUtils from "@utils/UrlUtils";
import {SearchKeys} from "@enums/SearchKeys";
import {LocationDomain} from "@domain/models/search/SugestionType";
import {useGetCurrentLocationViewModel} from "@root/framework/hooks/useGetCurrentLocationViewModel";
import {useLocation, useNavigate} from "react-router-dom";
import {useInjection} from "@root/ioc.react";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import {SaveCurrentLocationUseCase} from "@domain/usecases/search/SaveCurrentLocationUseCase";
import TYPES from "@di/viewmodels/ViewModelTypes";
import {useTranslation} from "react-i18next";
import SearchLocationUseCase from "@domain/usecases/location/SearchLocationUseCase";
import {GetCurrentLocationUseCase} from "@domain/usecases/search/GetCurrentLocationUseCase";
import SearchUseCase from "@domain/usecases/search/SearchUseCase";
import {PostData} from "@domain/models/post/PostResponseV1";
import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";
import {Path} from "@enums/Path";
import './SearchMobileDialog.scss';

interface SearchMobileDialogProps {
    hide(): void
}

function SearchMobileDialog({hide}: SearchMobileDialogProps) {

    const searchLocationUseCase = useInjection<IProvider<SearchLocationUseCase>>(TYPES.SearchLocationUseCase).provide()
    const getCurrentLocationUseCase = useInjection<IProvider<GetCurrentLocationUseCase>>(TYPES.GetCurrentLocationUseCase).provide()
    const searchUseCase = useInjection<IProvider<SearchUseCase>>(TYPES.SearchUseCase).provide()

    const {stateLocation, searchLocation} = useSearchLocationViewModel(searchLocationUseCase);
    const [selectedCity, setSelectedCity] = useState<string | undefined>(undefined)
    const saveCurrentLocationUseCase = useInjection<IProvider<SaveCurrentLocationUseCase>>(TYPES.SaveCurrentLocationUseCase).provide()

    const {stateSaveCurrentLocation, saveCurrentLocation} = useSaveCurrentLocationViewModel(saveCurrentLocationUseCase);

    const {stateSearch, searchPosts} = useSearchViewModel(searchUseCase);

    const [showSearchCitiesContainer, setShowSearchCitiesContainer] = useState(false)

    const {stateGetCurrentLocation, getCurrentLocation} = useGetCurrentLocationViewModel(getCurrentLocationUseCase);
    const location = useLocation();

    const catalogId = "CAT-1";
    const {t} = useTranslation();

    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const inputRef = useRef<HTMLInputElement>(null);

    function updateQueryParam(body: any) {
        const newParams: NavigationParams = body
        const updatedSearchParams = NavigationUtils.buildSearchParams(newParams);
        navigate(Path.SEARCH + "/" + updatedSearchParams);
    }

    useEffect(() => {
        // Add the class when the component mounts
        document.body.classList.add("search-mobile-dialog__dialog-open");

        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove("search-mobile-dialog__dialog-open");
        };
    }, []);

    function handleSearch() {
        const isEmpty = searchTerm?.trim() == ""
        if (isEmpty) {
            return
        }
        hide()
        updateQueryParam({
            [SearchKeys.QUERY_SEARCH_KEY]: searchTerm
        })
    }

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === 'NumpadEnter') {
            handleSearch();
        }
    };


    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [searchTerm]);

    function onPostClicked(postData: PostData) {

        updateQueryParam({
            [SearchKeys.QUERY_SEARCH_KEY]: postData.title,
            [SearchKeys.LAT_SEARCH_KEY]: postData.place?.lat,
            [SearchKeys.LON_SEARCH_KEY]: postData.place?.lon,
        })
        hide() //Make sure that dialog is hidden
    }


    function handleRemoveCity() {
        setSelectedCity(undefined)
    }

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        const paramsMap = UrlUtils.getParamsMap(location.search)

        const currentLocation = stateGetCurrentLocation.locationDomain
        //Set the query
        paramsMap.set(SearchKeys.QUERY_SEARCH_KEY, event.target.value)

        if (currentLocation?.lat && currentLocation?.lon) {
            paramsMap.set(SearchKeys.PLACE_SEARCH_KEY, currentLocation.name.toString())
            paramsMap.set(SearchKeys.LAT_SEARCH_KEY, currentLocation?.lat.toString())
            paramsMap.set(SearchKeys.LON_SEARCH_KEY, currentLocation?.lon.toString())
        }

        searchPosts(catalogId, paramsMap);
    };

    function onAnyLocationButtonClick() {
        setShowSearchCitiesContainer(true)

    }

    function searchLocationOnList(locationDomain: LocationDomain) {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        //Set the query
        paramsMap.set(SearchKeys.PLACE_SEARCH_KEY, locationDomain.name.toString())

        paramsMap.set(SearchKeys.LON_SEARCH_KEY, locationDomain.lon.toString())
        paramsMap.set(SearchKeys.LAT_SEARCH_KEY, locationDomain.lat.toString())
        searchPosts(catalogId, paramsMap);
    }

    function onLocationClick(isFromAnyLocation: boolean, locationDomain: LocationDomain | undefined) {
        if (!locationDomain) {
            onAnyLocationButtonClick()
            return
        }
        searchLocationOnList(locationDomain);

        saveCurrentLocation(isFromAnyLocation, locationDomain)

        getCurrentLocation()
    }

    useEffect(() => {
        //Get main cities
        searchLocation("")
        getCurrentLocation()
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    function onBackPressed(event: React.MouseEvent) {
        event.stopPropagation()
        setShowSearchCitiesContainer(false)
    }


    function handleClickLocation(isFromAnyLocation: boolean, locationDomain: LocationDomain) {
        setShowSearchCitiesContainer(false)
        setSelectedCity(locationDomain.name)
        saveCurrentLocation(isFromAnyLocation, locationDomain)
        searchLocationOnList(locationDomain);
        getCurrentLocation()
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault(); // Prevent default form submission
        handleSearch(); // Call the same search handling logic
    };

    return <div className="search-mobile-dialog__overlay">

        {!showSearchCitiesContainer ? (<div className="search-mobile-dialog__dialog">
                <header className="search-mobile-dialog__dialog__search-header">
                    <img className="search-mobile-dialog__dialog__search-header__close-button" src={arrowBack}
                         onClick={() => hide()}/>
                    <h2>{t(R.searchResults_search_searchMobileTitle)}</h2>
                </header>

                <div className="search-mobile-dialog__dialog__recommendations-list">
                    {stateSearch.postResultData?.data.map((postData, index) => (
                        <div key={index} onClick={(e: React.MouseEvent) => onPostClicked(postData)}
                             className="search-mobile-dialog__dialog__recommendations-list__recommendation-item">
                            <div
                                className="search-mobile-dialog__dialog__recommendations-list__recommendation-item__recommendation-type">{t(CatalogUtils.getPrettyType(postData.post_meta))}</div>
                            <div
                                className="search-mobile-dialog__dialog__recommendations-list__recommendation-item__recommendation-description ">{postData.title}</div>
                            <div
                                className="search-mobile-dialog__dialog__recommendations-list__recommendation-item__recommendation-location">{postData?.place?.address}</div>
                        </div>
                    ))}
                </div>

                <div className="search-mobile-dialog__cities-filter">
                    <ScrollableButtonGroup
                        onLocationClick={onLocationClick}
                        locations={stateLocation.locationData ?? []}
                        selectedCity={selectedCity}
                        handleRemoveCity={handleRemoveCity}
                        stateGetCurrentLocation={stateGetCurrentLocation}
                    />
                </div>

                 <form onSubmit={handleSubmit}  className="search-mobile-dialog__dialog__search-bar">
                    <input
                        type="text"
                        ref={inputRef}
                        placeholder={t(R.landingPage_search_hint)}
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </form>
         </div>) :
            (<div className="search-mobile-dialog__cities">
                <Cities onBackPressed={onBackPressed} handleClickLocation={handleClickLocation}/></div>)
        }
    </div>

}

export default SearchMobileDialog;
