import React from "react";
import {  Row, Col, Image } from "react-bootstrap";
import playStoreImg from "@assets/playStore.svg";
import iosStoreImg from "@assets/apple_app_store_logo.svg";
import R from "@strings/R";

import "./AccessApp.scss";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const AccessApp: React.FC = (): JSX.Element => {

  const {t} = useTranslation();

  return (
    <div className="access-app">
      <h5 className="access-app__title"> {t(R.anythingOnGo)}</h5>

      <div className="access-app__content">
        <h6 className="access-app__content__title">
          {t(R.playStorageTitle)}
        </h6>

        <p className="access-app__content__desc">
          {t(R.playStorageDescription)}
        </p>

        <div className="access-app__content__actions">
          <div className="access-app__content__actions__item">
            <Link
              to={process.env.REACT_APP_PLAY_STORAGE_LINK  ?? ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={playStoreImg}
                width="159px"
                height="48px"
                alt="Android Play Store Logo"
              />
            </Link>
          </div>

          <div className="access-app__content__actions__item">
            <Image
              src={iosStoreImg}
              width="159px"
              height="48px"
              alt="Apple App Store Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessApp;
