import React, { useEffect } from "react";
import PostViewModel from "@viewmodels/PostViewModel";

import { observer } from "mobx-react";
import { Col, Row } from "react-bootstrap";
import CardView from "@presentation/views/components/CardView";
import { useInjection } from "../../../../../ioc.react";
import { IProvider } from "@di/viewmodels/ViewModelProvider";
import TYPES from "@di/viewmodels/ViewModelTypes";
import { PostData } from "@domain/models/post/PostResponseV1";
import ContentBox from "../../components/ContentBox/ContentBox";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./Posts.scss";

interface PostsProps {
    _postViewModel: PostViewModel;
}

export const Posts: React.FC = (): JSX.Element => {
    const postViewModel = useInjection<IProvider<PostViewModel>>(
        TYPES.PostViewModel
    ).provide();

    useEffect(() => {
        postViewModel.getMyPosts();
    }, []);

    const handleDeleteClick = (post: PostData) => {
        postViewModel.deleteMyPost(post.id);
    };

    const MyPostView = observer(({ _postViewModel }: PostsProps) => {
        return (
          <div className="my-posts">
            <ContentBox>
              <Row className="gx-0 mt-4">
                  <h4 className="my-posts__title">My Posts</h4>

                  <Row className="gy-2 gx-0 pb-5">
                      {_postViewModel?.isLoadingRequest ? (
                          Array.from(new Array(4)).map((it, index) => (
                              <Col md={3} key={index}>
                                  <CardView isLoading={true} />
                              </Col>
                          ))
                      ) : _postViewModel.getMyPostsData()?.meta?.total === 0 ? (
                          <div>0 results</div>
                      ) : (
                          _postViewModel.getMyPostsData()?.data?.map((post) => (
                              <Col
                                  md={3}
                                  style={{ paddingRight: "25px" }}
                                  key={post.id}
                              >
                                  <div className="my-posts__item">
                                      <CardView post={post} id="new rail" />
                                      <button
                                          onClick={() => handleDeleteClick(post)}
                                          className="my-posts__item__delete btn btn-danger"
                                      >
                                          <i className="bi bi-trash"></i> Delete
                                      </button>
                                  </div>
                              </Col>
                          ))
                      )}
                  </Row>
              </Row>
            </ContentBox>
          </div>
        );
    });

    return (
        <>
            <MyPostView _postViewModel={postViewModel}></MyPostView>
        </>
    );
};
