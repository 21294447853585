import CategoriesViewModel from '@viewmodels/CategoriesViewModel';
import PostViewModel from '@viewmodels/PostViewModel';
import RailViewModel from '@viewmodels/RailViewModel';
import SearchViewModel from '@viewmodels/SearchViewModel';
import UserViewModel from '@viewmodels/UserViewModel';
import { Container } from 'inversify';
import {
    CategoriesProvider,
    CreatePostUseCaseProvider,
    DeleteFavouriteUseCaseProvider,
    DeleteMyPostUseCaseProvider, DeletePostFromHistoryUseCaseProvider, GetCurrentLocationUseCaseProvider,
    GetMyFavouritesUseCaseProvider,
    GetMyPostsUseCaseProvider,
    GetMySavedSearchesUseCaseProvider, GetOtherPostsUseCaseProvider,
    GetPostDetailsUseCaseProvider,
    GetPostsInHistoryUseCaseProvider,
    GetTokenUseCaseProvider,
    GetUserAccountUseCaseProvider,
    IProvider,
    PostProvider,
    RailsProvider, SaveCurrentLocationUseCaseProvider, SavePostInHistoryUseCaseProvider, SaveUserAccountUseCaseProvider,
    SearchLocationUseCaseProvider,
    SearchProvider,
    SearchRecommendationsUseCaseProvider,
    SearchUseCaseProvider,
    SignOutUseCaseProvider,
    UserProvider
} from './ViewModelProvider';
import TYPES from './ViewModelTypes';
import GetPostDetailsUseCase from "@domain/usecases/post/GetPostDetailsUseCase";
import GetMyPostsUseCase from "@domain/usecases/post/GetMyPostsUseCase";
import DeleteMyPostUseCase from "@domain/usecases/post/DeleteMyPostUseCase";
import GetMyFavouritesUseCase from "@domain/usecases/favourites/GetMyFavouritesUseCase";
import CreatePostUseCase from "@domain/usecases/post/CreatePostUseCase";
import DeleteFavouriteUseCase from "@domain/usecases/favourites/DeleteFavouriteUseCase";
import GetUserAccountUseCase from "@domain/usecases/user/GetUserAccountUseCase";
import GetTokenUseCase from "@domain/usecases/auth/GetTokenUseCase";
import SignOutUseCase from "@domain/usecases/auth/SignOutUseCase";
import SearchUseCase from "@domain/usecases/search/SearchUseCase";
import SearchLocationUseCase from "@domain/usecases/location/SearchLocationUseCase";
import GetMySavedSearchesUseCase from "@domain/usecases/saved/GetMySavedSearchesUseCase";
import SearchRecommendationsUseCase from "@domain/usecases/search/SearchRecommendationsUseCase";
import {GetPostsInHistoryUseCase} from "@domain/usecases/search/GetPostsInHistoryUseCase";
import {DeletePostFromHistoryUseCase} from "@domain/usecases/search/DeletePostFromHistoryUseCase";
import {SavePostInHistoryUseCase} from "@domain/usecases/search/SavePostInHistoryUseCase";
import {SaveCurrentLocationUseCase} from "@domain/usecases/search/SaveCurrentLocationUseCase";
import {GetCurrentLocationUseCase} from "@domain/usecases/search/GetCurrentLocationUseCase";
import SaveUserAccountUseCase from "@domain/usecases/user/SaveUserAccountUseCase";
import {GetOtherPostsUseCase} from "@domain/usecases/search/GetOtherPostsUseCase";

export const container = new Container();

container.bind<IProvider<CategoriesViewModel>>(TYPES.CatalogViewModel).to(CategoriesProvider);
container.bind<IProvider<PostViewModel>>(TYPES.PostViewModel).to(PostProvider);
container.bind<IProvider<RailViewModel>>(TYPES.RailViewModel).to(RailsProvider);
container.bind<IProvider<SearchViewModel>>(TYPES.SearchViewModel).to(SearchProvider);
container.bind<IProvider<UserViewModel>>(TYPES.UserViewModel).to(UserProvider);

//use cases
container.bind<IProvider<GetPostDetailsUseCase>>(TYPES.GetPostDetailsUseCase).to(GetPostDetailsUseCaseProvider);
container.bind<IProvider<GetMyPostsUseCase>>(TYPES.GetPostDetailsUseCase).to(GetMyPostsUseCaseProvider);
container.bind<IProvider<DeleteMyPostUseCase>>(TYPES.DeleteMyPostUseCase).to(DeleteMyPostUseCaseProvider);
container.bind<IProvider<GetMyFavouritesUseCase>>(TYPES.GetMyFavouritesUseCase).to(GetMyFavouritesUseCaseProvider);
container.bind<IProvider<CreatePostUseCase>>(TYPES.GetMyFavouritesUseCase).to(CreatePostUseCaseProvider);
container.bind<IProvider<DeleteFavouriteUseCase>>(TYPES.GetMyFavouritesUseCase).to(DeleteFavouriteUseCaseProvider);


container.bind<IProvider<GetUserAccountUseCase>>(TYPES.GetUserAccountUseCase).to(GetUserAccountUseCaseProvider);
container.bind<IProvider<SaveUserAccountUseCase>>(TYPES.SaveUserAccountUseCase).to(SaveUserAccountUseCaseProvider);

container.bind<IProvider<GetTokenUseCase>>(TYPES.GetTokenUseCase).to(GetTokenUseCaseProvider);
container.bind<IProvider<SignOutUseCase>>(TYPES.SignOutUseCase).to(SignOutUseCaseProvider);

container.bind<IProvider<SearchUseCase>>(TYPES.SearchUseCase).to(SearchUseCaseProvider);
container.bind<IProvider<SearchLocationUseCase>>(TYPES.SearchLocationUseCase).to(SearchLocationUseCaseProvider);
container.bind<IProvider<GetMySavedSearchesUseCase>>(TYPES.GetMySavedSearchesUseCase).to(GetMySavedSearchesUseCaseProvider);
container.bind<IProvider<SearchRecommendationsUseCase>>(TYPES.SearchRecommendationsUseCase).to(SearchRecommendationsUseCaseProvider);
container.bind<IProvider<GetPostsInHistoryUseCase>>(TYPES.GetPostsInHistoryUseCase).to(GetPostsInHistoryUseCaseProvider);
container.bind<IProvider<DeletePostFromHistoryUseCase>>(TYPES.DeletePostFromHistoryUseCase).to(DeletePostFromHistoryUseCaseProvider);
container.bind<IProvider<SavePostInHistoryUseCase>>(TYPES.SavePostInHistoryUseCase).to(SavePostInHistoryUseCaseProvider);

container.bind<IProvider<SaveCurrentLocationUseCase>>(TYPES.SaveCurrentLocationUseCase).to(SaveCurrentLocationUseCaseProvider);
container.bind<IProvider<GetCurrentLocationUseCase>>(TYPES.GetCurrentLocationUseCase).to(GetCurrentLocationUseCaseProvider);
container.bind<IProvider<GetOtherPostsUseCase>>(TYPES.GetOtherPostsUseCase).to(GetOtherPostsUseCaseProvider);


export default container