 import {useCallback, useState} from "react";
import SaveUserAccountUseCase from "@domain/usecases/user/SaveUserAccountUseCase";
 import {UserRequest} from "@domain/models/user/UserRequest";


export interface SaveUserViewState {
    result?: Boolean;
    isLoading?: boolean| null;
    error?: string | null;
}

export const useSaveAccountDetailsViewModel = (saveUserUseCase: SaveUserAccountUseCase) => {
    const [stateSaveUserUseCase, setState] = useState<SaveUserViewState>({isLoading: false});

    const saveUserDetailsViewModel = useCallback((useRequest: UserRequest) => {
        setState({isLoading: true});
         saveUserUseCase.execute(useRequest).then(result => {
                setState({result: result, isLoading: false, error: null});
            }
        )   .catch(error => {
            setState({error: 'Error fetching posts', isLoading: false});
        });
     }, [saveUserUseCase]);

    return {
        stateSaveUserUseCase,
saveUserDetails: saveUserDetailsViewModel
    };
};