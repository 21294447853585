import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {useMediaQuery} from "@mui/material";

import SearchViewModel from "@viewmodels/SearchViewModel";
import TYPES from "@di/viewmodels/ViewModelTypes";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import {Catalog, CatalogModel} from "@domain/models/catalog/CatalogModel";
import SearchUseCase from "@domain/usecases/search/SearchUseCase";
import PaginationView from "@presentation/views/components/Pagination";
import FilterMenu from "@presentation/views/components/FilterMenu";
import SearchBar from "@presentation/views/components/Search/SearchBarResultsPage";
import getCatalogConfig from "@utils/remote_config/CatalogConfig";
import UrlUtils from "@utils/UrlUtils";
import NavigationUtils, {NavigationParams} from "@utils/NavigationUtils";

import {useInjection} from "@root/ioc.react";
import {useSearchViewModel} from "@root/framework/hooks/useSearchViewModel";
import R from "@strings/R";

import SearchResultsList from "./SearchResultsList";
import SearchResultsMobile from "./SearchResultsMobile";
import SortResultsListFilter from "./SortResultsListFilter";
import ItemsPerPageFilter from "./ItemsPerPageFilter";
import ContentBox from "../../components/ContentBox/ContentBox";

import "./SearchResults.scss"
import SearchMobileDialog
    from "@presentation/views/components/Search/SearchExperience/SearchMobileDialog/SearchMobileDialog";


interface SearchResultsProps {
    searchViewModel: SearchViewModel;
}


// Note: Not using React.FC here due to potential issues with `observer` from mobx-react
const SearchResults: React.FunctionComponent<SearchResultsProps> = ({searchViewModel}: SearchResultsProps) => {
    const catalogId = "CAT-1";
    const location = useLocation();
    const {t} = useTranslation()
    let currentCatalog: Catalog | undefined;
    let catalogConfig: CatalogModel | undefined = getCatalogConfig();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 845px)');

    const searchUseCase = useInjection<IProvider<SearchUseCase>>(TYPES.SearchUseCase).provide()
    const {stateSearch, searchPosts} = useSearchViewModel(searchUseCase);

    currentCatalog = catalogConfig?.catalog.find((ct) => {
        if (ct.id === catalogId) {
            return ct;
        }
    });

    const handleSearch = () => {
        const paramsMap = UrlUtils.getParamsMap(location.search)
        searchPosts(catalogId, paramsMap)
    };

    useEffect(() => {
        handleSearch();
    }, []);

    useEffect(() => {
        handleSearch();
    }, [location]);

    const searchResults = stateSearch?.postResultData;
    const [isSearchMobileDialogOpen, setIsSearchMobileDialogOpen] = useState(false);

    function updateQueryParam(body: any) {
        const newParams: NavigationParams = body
        const updatedSearchParams = NavigationUtils.buildSearchParams(newParams);
        navigate(updatedSearchParams);
    }

    const toggleSearchMobileDialog = () => {
        setIsSearchMobileDialogOpen(!isSearchMobileDialogOpen);
    };

    const onHideChanged = (show: boolean) => {
    }

    return (
        <div className="search-results">
            {
                isMobile
                    ? (
                        <div className="search-results__mobile">
                            <SearchResultsMobile searchResults={searchResults} searchViewModel={searchViewModel}/>

                            {searchResults?.meta.total ? (
                                <div className="search-results__mobile__total">
                                    <span>{searchResults?.meta.total} {t(R.searchResults_search_numberOfResults)}
                                    </span>
                                </div>
                            ) : null}


                            <div className="search-results__mobile__content">
                                <ContentBox>
                                    <SearchResultsList searchResults={searchResults} searchViewModel={searchViewModel}/>
                                </ContentBox>
                            </div>

                            {isSearchMobileDialogOpen ?
                                <SearchMobileDialog hide={() => toggleSearchMobileDialog()}/> : null}

                        </div>
                    )
                    : (
                        <div className="search-results__tablet">
                            <SearchBar updateQueryParam={updateQueryParam}/>

                            <ContentBox>
                              <div className="search-results__content">
                                <div className="search-results__filter-column">
                                  <FilterMenu
                                    className={"search-results__filter"}
                                    showFilter={true}
                                    attributes={currentCatalog?.attributes}
                                    onHideChanged={onHideChanged}
                                    searchViewModel={searchViewModel}
                                    displayFilterLabel={true}
                                  />
                                </div>

                                <div className="search-results__results-column">
                                    <Row className="gx-0">
                                        <Row className="mb-2">
                                            <Col className="align-content-center" md={5}>
                                                {
                                                    searchResults?.meta.total  ?
                                                    (
                                                        <div className="header-search-results">
                                                            <span>{searchResults?.meta.total} {t(R.searchResults_search_numberOfResults)}</span>
                                                        </div>
                                                    ) : null
                                                }
                                            </Col>
                                            {searchResults?.meta.total !== 0 && (
                                              <Col md={6} className="ms-auto d-flex justify-content-end">
                                                <Col className="me-3 mb-2">
                                                  <SortResultsListFilter searchViewModel={searchViewModel}/>
                                                </Col>
                                                <Col>
                                                  <ItemsPerPageFilter searchViewModel={searchViewModel}/>
                                                </Col>
                                              </Col>
                                            )}
                                        </Row>

                                        <SearchResultsList
                                          searchResults={searchResults}
                                          searchViewModel={searchViewModel}
                                        />
                                    </Row>

                                    {searchResults?.data?.length !== 0 && (
                                        <PaginationView meta={searchResults?.meta} />
                                    )}
                                </div>
                              </div>
                            </ContentBox>
                        </div>
                    )
            }
        </div>
    );
}

export default SearchResults;
