import React, {useState} from 'react';

import SearchViewModel from "@viewmodels/SearchViewModel";

import ContentBox from "../../components/ContentBox/ContentBox";
import SearchResultsMobileFilters from "./SearchResultsMobileFilters";
import Button from "../../components/Button/Button";
import SortResultsListFilter from "./SortResultsListFilter";
import ItemsPerPageFilter from "./ItemsPerPageFilter";

import "./SearchResultsMobile.scss";
import R from "@strings/R";
import {useTranslation} from "react-i18next";
import StickySearch from "@presentation/views/components/Search/StickySearch/StickySearch";

interface SearchResultsMobileProps {
    searchResults: any;
    searchViewModel: SearchViewModel;
}

const SearchResultsMobile = ({searchResults, searchViewModel}: SearchResultsMobileProps) => {
    const [isShowingMobileFilters, setIsShowingMobileFilters] = useState(false);
    const {t} = useTranslation()

    const onMobileFiltersDisplayToggle = () => {
        setIsShowingMobileFilters(!isShowingMobileFilters);
    }

    return (
        <div className="search-results-mobile">
            {
                isShowingMobileFilters
                    ? <SearchResultsMobileFilters total={searchResults?.meta.total ?  searchResults?.meta.total : 0} searchViewModel={searchViewModel}
                                                  onClose={onMobileFiltersDisplayToggle}/>
                    : (
                        searchResults?.meta.total !== 0 && (
                            <ContentBox>
                                <div className="search-results-mobile__filter">
                                    <div className="search-results-mobile__filter__item">
                                        <SortResultsListFilter searchViewModel={searchViewModel}/>
                                    </div>

                                    <div className="search-results-mobile__filter__item">
                                        <ItemsPerPageFilter searchViewModel={searchViewModel}/>
                                    </div>

                                    <div className="search-results-mobile__filter__item">
                                        <Button
                                            type="secondary"
                                            otherClassName="search-results-mobile__filter__cta"
                                            onClick={onMobileFiltersDisplayToggle}
                                        >
                                            {t(R.searchResults_search_filtersMobileLabel)}
                                        </Button>
                                    </div>
                                </div>
                                <StickySearch/>
                            </ContentBox>
                        )
                    )
            }
        </div>
    )
}

export default SearchResultsMobile;
