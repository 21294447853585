import React from "react";
import {PostData} from "@domain/models/post/PostResponseV1";
import CatalogUtils from "@utils/CatalogUtils";
import {useTranslation} from "react-i18next";
import LocationIcon from "../../../Icons/LocationIcon";
import DeleteIcon from "../../../Icons/DeleteIcon";

import "./PostInfoRow.scss"

export interface RecommendationsItemProps {
    postData: PostData;
    onDelete?:(e: React.MouseEvent) => void;
}

const PostInfoRow: React.FC<RecommendationsItemProps> = ({ postData, onDelete }) => {
    const {t} = useTranslation();

    return <div className="post-info-row">
      <span className="post-info-row__item post-info-row__item--type">
        {t(CatalogUtils.getPrettyType(postData.post_meta))}
      </span>

      <span className="post-info-row__item post-info-row__item--title">
        {postData.title}
      </span>

      <span className="post-info-row__item post-info-row__item--location">
        <span className="post-info-row__item--location__icon">
          <LocationIcon />
        </span>

        <span className="post-info-row__item--location__text">{postData?.place?.admin}</span>
      </span>

      <span className="post-info-row__item post-info-row__item--catalog">
        {t(CatalogUtils.getPrettyCategory(postData))}
      </span>

      
        <span className="post-info-row__item post-info-row__item--delete">
          { onDelete && (
            <button onClick={onDelete}>
              <DeleteIcon />
            </button>
          )}
        </span>
    </div>
}

export default PostInfoRow