import {
    AUTH_TOKEN_API,
     USER_INFO_API_ID
} from "@domain/models/startup/ServiceId";
import {Startup} from "@domain/models/startup/Startup";
import {UserV1} from "@domain/models/user/UserV1";
import IUserRepository from "@domain/repository/user/IUserRepository";
import {UserToken} from "@domain/models/user/UserToken";
import {TOKEN_STORE_KEY} from "@utils/Constants";
import {ApiUtilsUtils} from "@utils/ApitUtils";
import api from "@data/Api";
import {UserRequest} from "@domain/models/user/UserRequest";

export default class UserApi implements IUserRepository {

    private startupConfig: Startup | undefined


    constructor(startupConfig: Startup | undefined) {
        this.startupConfig = startupConfig
    }

    getUserToken(firebaseTokenId: string): Promise<UserToken> {
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, AUTH_TOKEN_API);

        return api.post(url!!, {
            token: firebaseTokenId
        }).then(response => {
            return response.data; // Axios automatically parses JSON and puts it in response.data
        })
            .catch(error => {
                throw new Error(error.message); // Pass the error message
            });
    }


    getOtherUserAccount(userId: number): Promise<UserV1> {
        let url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, USER_INFO_API_ID)!! + '/' + +userId

        return api.get(url)
            .then(response => {
                return response.data; // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                throw new Error(error.message); // Pass the error message
            });
    }

    getMyUserAccount(): Promise<UserV1> {
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, USER_INFO_API_ID);
        const token = localStorage.getItem(TOKEN_STORE_KEY) ?? '';

        return api.get(url!!, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then(response => {
                return response.data; // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                throw new Error(error.message); // Pass the error message
            });

    }

    saveUserAccount(
        useRequest: UserRequest
    ): Promise<Boolean> {
        const url = ApiUtilsUtils.getServiceEndPointByServiceId(this.startupConfig, USER_INFO_API_ID);

         return api.post(url!!,
             useRequest)
            .then(response => {
                return  true; // Axios automatically parses JSON and puts it in response.data
            })
            .catch(error => {
                throw new Error(error.message); // Pass the error message
            });
    }

}