import {Attribute} from "@domain/models/catalog/CatalogModel";
import {FilterInputTypes} from "@domain/models/enums/FilterInputTypes";
import InputCheckBox from "@presentation/views/components/FilterInput/InputCheckBox";
import {InputListRange} from "@presentation/views/components/FilterInput/InputListRange/InputListRange";
import InputSelect from "@presentation/views/components/FilterInput/InputSelect";
import PriceFilter from "@presentation/views/components/FilterInput/PriceFilter/PriceFilter";
import SearchLocationInput from "@presentation/views/components/SearchLocationInput/SearchLocationInput";
import SearchViewModel from "@viewmodels/SearchViewModel";
import React, {ChangeEvent} from "react";
import InputChips from "@presentation/views/components/FilterInput/InputChips";

class FactoryMetaFilter {

    static createMetaFilter(attr: Attribute): JSX.Element | undefined {
         switch (attr.type_for_search || attr.type) {
            case FilterInputTypes.CHECKED_LIST: {
             return getCheckedListRepresentation(attr);
            }

            case FilterInputTypes.SEARCH_RADIO_LIST: {
                return getRadioListRepresentation(attr);
            }

            case FilterInputTypes.TWO_VALUES_RANGE: {
                return getTwoValuesRangeRepresentation(attr);
            }

            case FilterInputTypes.COST_PERIOD: {
                return getCostPeriodRepresentation(attr);
            }

            case FilterInputTypes.COST: {
                return getCostRepresentation(attr);
            }
            default: {
                return undefined;
            }
        }
    }

    static createFixMetaFilter(
        componentType: FilterInputTypes,
        searchViewModel: SearchViewModel,
        attrb: Attribute | undefined = undefined,
        onSelectChanged: ((event: ChangeEvent<HTMLSelectElement>) => void) | undefined  = undefined
    ): JSX.Element | undefined {

        switch (componentType) {

            case FilterInputTypes.SEARCH_LOCATION_INPUT: {
                return getSearchLocationInputRepresentation(searchViewModel);
            }

            case FilterInputTypes.SEARCH_RADIO_LIST: {
                return getSearchRadiusInputRepresentation(attrb!!);
            }

            case FilterInputTypes.SORT_RESULT_LIST: {
                return getSortResultListRepresentation(attrb, onSelectChanged)
            }

            case FilterInputTypes.ITEMS_PER_PAGE: {
                return getItemsPerPageRepresentation(attrb, onSelectChanged)
            }


            default: {
                return undefined;
            }
        }
    }
}
export default FactoryMetaFilter;

function getItemsPerPageRepresentation(attrb: Attribute | undefined, onSelectChanged: ((event: ChangeEvent<HTMLSelectElement>) => void) | undefined ) {
    return <InputSelect attr={attrb!!} onSelectChanged={onSelectChanged}  />;
}

function getSortResultListRepresentation(attrb: Attribute | undefined,    onSelectChanged: ((event: ChangeEvent<HTMLSelectElement>) => void) | undefined) {
    return <InputSelect attr={attrb!!}  onSelectChanged={onSelectChanged} />;
}

function getCheckedListRepresentation(item: Attribute) {
    return <InputCheckBox attribute={item}/> ;
}

function getCostRepresentation(item: Attribute): JSX.Element | undefined {
    return <PriceFilter  attribute={item} />;
}

function getCostPeriodRepresentation(item: Attribute): JSX.Element | undefined {
    return <InputChips columns={4}  type={'price-of'} attribute={item} />;
}

function getRadioListRepresentation(item: Attribute): JSX.Element {
    return <InputChips columns={2} attribute={item}/>;
}

function getTwoValuesRangeRepresentation(
    item: Attribute
): JSX.Element | undefined {
    return <InputListRange attribute={item} />;
}

function getSearchLocationInputRepresentation(
    searchViewModel: SearchViewModel
): JSX.Element | undefined {
    return <SearchLocationInput searchViewModel={searchViewModel} />;
}

function getSearchRadiusInputRepresentation(
    attribute: Attribute
): JSX.Element | undefined {
    return <InputChips columns={4} attribute={attribute}/>;
}
