import * as React from 'react';

const CameraIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="M8.61671 6.4H11.3837M5.16714 19H14.8333C17.379 19 18.3936 17.479 18.5135 15.625L18.9931 8.191C19.023 7.73005 18.9557 7.26801 18.7953 6.83345C18.635 6.39889 18.385 6.00103 18.0608 5.66447C17.7366 5.32791 17.3452 5.0598 16.9106 4.87672C16.4761 4.69363 16.0076 4.59945 15.5343 4.6C14.9717 4.6 14.4552 4.285 14.1969 3.799L13.5328 2.494C13.1085 1.675 12.0017 1 11.0609 1H8.94875C7.99874 1 6.89192 1.675 6.46764 2.494L5.80356 3.799C5.5453 4.285 5.02879 4.6 4.46615 4.6C2.46467 4.6 0.878232 6.247 1.00736 8.191L1.48698 15.625C1.59766 17.479 2.62146 19 5.16714 19Z"
        stroke="white" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
      <path 
        d="M10.0001 15.4C11.6511 15.4 12.9977 14.086 12.9977 12.475C12.9977 10.864 11.6511 9.55005 10.0001 9.55005C8.34907 9.55005 7.00244 10.864 7.00244 12.475C7.00244 14.086 8.34907 15.4 10.0001 15.4Z"
        stroke="white" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CameraIcon;




