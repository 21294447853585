import {SearchKeys} from "@enums/SearchKeys";

export interface NavigationParams {
    [key: string]: string | string[];

}

class NavigationUtils {

    static buildSearchParams(newParams: NavigationParams, currentPath: string | null = null): string {
        let mergedParams: NavigationParams = {};

        if (currentPath == null) {
            const currentSearchParams = new URLSearchParams(window.location.search);
            if (currentSearchParams.has(SearchKeys.PAGE_KEY)) {
                currentSearchParams.delete(SearchKeys.PAGE_KEY); // Remove "page" if it exists
            }

            const currentParams: NavigationParams = Object.fromEntries(currentSearchParams.entries());

            mergedParams = {...currentParams, ...newParams};
        } else {
            mergedParams = newParams;
        }

        const searchParamsString = Object.entries(mergedParams)
            .filter(([, value]) => {
                if (Array.isArray(value)) {
                    return value.filter(item => item !== "").length > 0;
                } else {
                    return value !== "";
                }
            })
            .map(([key, value]) => {
                if (Array.isArray(value)) {
                    return `${key}=${value.join(',')}`;
                } else {
                    return `${key}=${value}`;
                }
            })
            .join('&');

        return currentPath ? `${currentPath}?${searchParamsString}` : `?${searchParamsString}`;
    }


}

export default NavigationUtils;