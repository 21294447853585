import SearchRepository from "@domain/repository/search/ISearchRepository";
import { PostResultData} from "@domain/models/post/PostResponseV1";

export class GetOtherPostsUseCase {

    private searchRepository: SearchRepository

    public constructor(searchRepository: SearchRepository) {
        this.searchRepository = searchRepository
    }

    execute(userId: number,  page:number) : Promise<PostResultData> {
        return this.searchRepository.getOtherPosts(userId, page);
    }
}