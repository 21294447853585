import {Value} from "@domain/models/catalog/CatalogModel";
import {FilterInputTypes} from "@domain/models/enums/FilterInputTypes";
import {PriceKeyword} from "@domain/models/enums/PricePerTypes";
import {CatalogAttribute, PostData} from "@domain/models/post/PostResponseV1";
import getAttrbIconsMap from "@utils/remote_config/AttrbIconsConfig";
import {t} from "i18next";
import React from "react";
import classNames from 'classnames';
import {DescriptionBuilderUtils} from "@utils/DescriptionBuilderUtils";

class FactoryFilterAttributes {


    static createAttributeRepresentation(catalogAtrrb: CatalogAttribute): JSX.Element {
        switch (catalogAtrrb.type) {
            case FilterInputTypes.BINARY_VALUE: {
                return getBinaryRepresentation(catalogAtrrb)
            }
            case FilterInputTypes.VALUE: {
                return <strong>{catalogAtrrb.values[0].value}</strong>
            }

            case FilterInputTypes.SIGNED_INT_VALUE: {
                return <strong>{catalogAtrrb.values[0].value}</strong>
            }
            case FilterInputTypes.CHECKED_LIST: {
                return getCheckListRepresentation(catalogAtrrb.values)
            }
            case FilterInputTypes.COST: {
                return <strong>{catalogAtrrb.search_key}</strong>
            }
            case FilterInputTypes.SEARCH_RADIO_LIST: {
                return getRadioListRepresentation(catalogAtrrb.values)
            }

            default: {
                return <strong></strong>
            }
        }
    }


    static createPostAttributes(post: PostData) {
        let attrbIcons = getAttrbIconsMap()
        return <div
            className="row mt-4"> {post.post_meta?.catalog_attributes?.filter(it => attrbIcons?.icons?.[it.search_key] != undefined)?.map(attr => {
            return <div className="col-6">{FactoryFilterAttributes.createAttributeRepresentation(attr)} </div>
        })}</div>
    }


    static createDescriptionMeta(post: PostData) {
        const filteredAttributes = DescriptionBuilderUtils.filterCatalogAttributes(post);

        if (!filteredAttributes) return null;

        const attributesWithText = filteredAttributes.map((attr, index) => (
            <React.Fragment key={attr.search_key}>
                <div className={classNames("description-attribute", "horizontal-item")}>
                    <span className="attribute-name">{t(attr.name_dict_key)}</span>
                    <span
                        className="attribute-value">{FactoryFilterAttributes.createAttributeRepresentation(attr)}</span>
                </div>
                {index !== filteredAttributes.length - 1 && <span className="separator">|</span>}
            </React.Fragment>
        ));

        return <div className="description-meta">{attributesWithText}</div>;
    }

    static createFixCost(post: PostData) {
        if (!post || !post.price_meta) return null;

        const {price_meta, post_meta} = post;
        const {
            exchangeCurrency,
            convertedExchangePrice,
            exchangePrice,
            convertedPeriod,
        } = price_meta;
        const price = Math.ceil(Number(exchangePrice)).toLocaleString();

        const convertedExchangePriceLocale = Math.ceil(Number(convertedExchangePrice)).toLocaleString();

        const convertedPeriodTrans = convertedPeriod ? PriceKeyword[convertedPeriod] : "";

        const periodKey =  post_meta?.price_period;
        const period = periodKey ? PriceKeyword[periodKey] : "";

        if(convertedExchangePriceLocale != "0" &&  convertedPeriodTrans!="" && convertedPeriodTrans !=period){
            return (
                <>
                    {exchangeCurrency} {price} {period} ({convertedExchangePriceLocale} {convertedPeriodTrans})
                </>
            );
        }

        return (
            <>
                {exchangeCurrency} {price} {period}
            </>
        );
    }


}

export default FactoryFilterAttributes


function getRadioListRepresentation(value: Value[]): JSX.Element {
    return <> {value.map(it => {
        return t(it.name_dict_key);
    })}</>
}

function getBinaryRepresentation(catalogAttrb: CatalogAttribute): JSX.Element {
    let attrbIcons = getAttrbIconsMap()

    let label = <p><p><strong> <i className={attrbIcons?.icons!![catalogAttrb.search_key]}
                                  style={{fontSize: '1.5em'}}></i> </strong> {t(catalogAttrb?.name_dict_key)} </p></p>
    if (catalogAttrb.values[0].value == "true") {
        return label
    }
    return <del>{label}</del>
}


function getCheckListRepresentation(value: Value[]): JSX.Element {
    return <> {value.map((it, index) => {
        return <> <span>{t(it.name_dict_key)}</span>
            {index !== value.length - 1 && <span className="col-auto">,</span>}</>

    })}</>
}

