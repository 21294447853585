export enum R {
    generic_lb_app_name = 'generic_lb_app_name',
    landingPage_search_lb_title = "landingPage_search_lb_title",
    search_experience_title = "search_experience_title",
    landingPage_search_lb_sub_title = "landingPage_search_lb_sub_title",
    landingPage_search_hint = "landingPage_search_hint",
    landingPage_location_hint = "landingPage_location_hint",
    landingPage_search_button = "landingPage_search_button",
    landingPage_filter_button = "landingPage_filter_button",
    global_no_matches_found = "global_no_matches_found",
    global_please_try_again = "global_please_try_again",
    global_reset_filter_action = "global_reset_filter_action",
    category_name_vehicles = "category_name_vehicles",
    category_name_cars = "category_name_cars",
    catalog_attribute_name_color = "catalog_attribute_name_color",
    key_color_red = "key_color_red",
    key_color_green = "key_color_green",
    key_color_black = "key_color_black",
    catalog_attribute_name_engine_type = "catalog_attribute_name_engine_type",
    car_engine_type_petrol = "car_engine_type_petrol",
    car_engine_type_diesel = "car_engine_type_diesel",
    car_engine_type_electric = "car_engine_type_electric",
    car_engine_type_hybrid = "car_engine_type_hybrid",
    catalog_attribute_name_price = "catalog_attribute_name_price",
    category_name_bicycles = "category_name_bicycles",
    catalog_attribute_name_color_hex = "catalog_attribute_name_color_hex",
    number_of_seats = "number_of_seats",
    category_name_real_estate = "category_name_real_estate",
    subcategory_name_apartment = "subcategory_name_apartment",
    catalog_attribute_name_wifi_allowance = "catalog_attribute_name_wifi_allowance",
    catalog_attribute_name_room_amount = "catalog_attribute_name_room_amount",
    subcategory_name_detached_houses = "subcategory_name_detached_houses",
    catalog_attribute_name_guest_number = "catalog_attribute_name_guest_number",
    subcategory_name_flats = "subcategory_name_flats",
    catalog_attribute_name_type_of_place = "catalog_attribute_name_type_of_place",
    catalog_attribute_name_type_of_place_value_entire_place = "catalog_attribute_name_type_of_place_value_entire_place",
    catalog_attribute_name_type_of_place_value_private_room = "catalog_attribute_name_type_of_place_value_private_room",
    catalog_attribute_name_type_of_place_value_hotel_room = "catalog_attribute_name_type_of_place_value_hotel_room",
    catalog_attribute_name_type_of_place_value_shared_room = "catalog_attribute_name_type_of_place_value_shared_room",
    catalog_attribute_name_free_cancellation = "catalog_attribute_name_free_cancellation",
    catalog_attribute_name_kitchen = "catalog_attribute_name_kitchen",
    catalog_attribute_name_air_conditioning = "catalog_attribute_name_air_conditioning",
    catalog_attribute_name_washer = "catalog_attribute_name_washer",
    catalog_attribute_name_free_parking = "catalog_attribute_name_free_parking",
    catalog_attribute_name_pool = "catalog_attribute_name_pool",
    catalog_attribute_name_smoking_allowed = "catalog_attribute_name_smoking_allowed",
    catalog_attribute_name_host_language = "catalog_attribute_name_host_language",
    catalog_attribute_name_host_language_value_english = "catalog_attribute_name_host_language_value_english",
    catalog_attribute_name_host_language_value_german = "catalog_attribute_name_host_language_value_german",
    catalog_attribute_name_host_language_value_french = "catalog_attribute_name_host_language_value_french",
    catalog_attribute_name_beds_amount = "catalog_attribute_name_beds_amount",
    catalog_attribute_name_bedrooms_amount = "catalog_attribute_name_bedrooms_amount",
    catalog_attribute_name_bathrooms_amount = "catalog_attribute_name_bathrooms_amount",
    subcategory_name_mobile_home = "subcategory_name_mobile_home",
    rail_title_top_categories = "rail_title_top_categories",
    footer_title_private_policy = "footer_title_private_policy",
    footer_title_terms_service_policy = "footer_title_terms_service_policy",
    footer_title_how_works_policy = "footer_title_how_works_policy",
    footer_title_faqs_policy = "footer_title_faqs_policy",
    post_details_call_action_label = "post_details_call_action_label",
    post_details_call_email_label = "post_details_call_email_label",
    post_details_call_share_label = "post_details_call_share_label",
    searchResults_search_radius = "searchResults_search_radius",
    searchResults_search_numberOfResults = "searchResults_search_numberOfResults",
    searchResults_items_per_page = "searchResults_items_per_page",
    searchResults_sort_list_results = "searchResults_sort_list_results",
    searchResults_searchCities_not_location_found = "searchResults_searchCities_not_location_found",
    searchResults_search_searchMobileTitle = "searchResults_search_searchMobileTitle",

    catalog_attribute_min_price = "catalog_attribute_min_price",
    catalog_attribute_max_price = "catalog_attribute_max_price",
    searchResults_search_loading = "searchResults_search_loading",
    searchResults_search_noResults = "searchResults_search_noResults",
    searchResults_search_extraFilters = "searchResults_search_extraFilters",
    searchResults_searchMobile_title = "searchResults_searchMobile_title",
    searchResults_search_updateResults = "searchResults_search_updateResults",
    searchResults_recent_search_title = "searchResults_recent_search_title",
    searchResults_recommendations_title = "searchResults_recommendations_title",
    searchResults_searchResult_title = "searchResults_searchResult_title",
    searchResults_searchCities_title = "searchResults_searchCities_title",
    searchResults_searchCities_placeHolder = "searchResults_searchCities_title",

     postDetails_detailsAdvertiser_createdAt = "postDetails_detailsAdvertiser_createdAt",
    postDetails_detailsAdvertiser_createdBy = "postDetails_detailsAdvertiser_createdBy",
    postDetails_images_photoNumber = "postDetails_images_photoNumber",
    howItWorks_first_item_title = "howItWorks_first_item_title",
    howItWorks_first_item_description = "howItWorks_first_item_description",
    howItWorks_second_item_title = "howItWorks_second_item_title",
    howItWorks_second_item_description = "howItWorks_second_item_description",
    howItWorks_third_item_title = "howItWorks_third_item_title",
    howItWorks_third_item_description = "howItWorks_third_item_description",
    howItWorks_four_item_title = "howItWorks_four_item_title",
    howItWorks_four_item_description = "howItWorks_four_item_description",
    howItWorks_five_item_title = "howItWorks_five_item_title",
    howItWorks_five_item_description = "howItWorks_five_item_description",
    postDescription_title_attrbs = "postDescription_title_attrbs",
    postDescription_descriptionTitle_attrbs = "postDescription_descriptionTitle_attrbs",
    postDescription_locationTitle = "postDescription_locationTitle",
    searchExperience_search_no_results = "searchExperience_search_no_results",
    searchExperience_recent_results_no_results = "searchExperience_recent_results_no_results",
    catalog_name_properties = "catalog_name_properties",
    cardView_publishTime_justNow = "cardView_publishTime_justNow",
    cardView_publishTime_minutesAgo = "cardView_publishTime_minutesAgo",
    cardView_publishTime_hourAgo = "cardView_publishTime_hourAgo",
    cardView_publishTime_daysAgo = "cardView_publishTime_daysAgo",
    postDetails_backToHomeButton = "postDetails_backToHomeButton",
    createPostAction = "createPostAction",
    createPostTitle = "createPostTitle",
    createPostDescription = "createPostDescription",
    createPostLocation = "createPostLocation",
    createPostPhoneNumberCode = "createPostPhoneNumberCode",
    createPostPhoneNumber = "createPostPhoneNumber",
    generalErrorMessage = "generalErrorMessage",
    createPostImages = "createPostImages",
    createPostChooseImages = "createPostChooseImages",
    createPostUploadImageWarning = "createPostUploadImageWarning",
    createPostContactWayInfo = "createPostContactWayInfo",
    createPostContactWay = "createPostContactWay",
    realStatePriceAttribute = "realStatePriceAttribute",
    recentRentalViews = "recentRentalViews",
    anythingOnGo = "anythingOnGo",
    remove = "remove",
    postAgreement = "postAgreement",
    termsOfService = "termsOfService",
    privacyPolicy = "privacyPolicy",
    dataProcessing = "dataProcessing",
    selectedLanguage = "selectedLanguage",
    myFavouritesPostAction = "myFavouritesPostAction",
    profileAction = "profileAction",
    myPostAction = "myPostAction",
    goToHomePageAction = "goToHomePageAction",
    savedPostAction = "savedPostAction",
    auth_login_title = 'auth_login_title',
    auth_sign_out = 'auth_sign_out',
    accountMenuName = 'accountMenuName',
    pageNotFound_404_message = "pageNotFound_404_message",
    pageNotFound_404_title = "pageNotFound_404_title",
    maintenanceDescriptionFirstLine = "maintenanceDescriptionFirstLine",
    maintenanceDescriptionSecondLine = "maintenanceDescriptionSecondLine",
    currencyMenuItemLabel = "currencyMenuItemLabel",
    firstRentalReview = "firstRentalReview",
    secondRentalReview = "secondRentalReview",
    playStorageTitle = "playStorageTitle",
    playStorageDescription = "playStorageDescription",
    searchResults_search_filtersMobileLabel = "searchResults_search_filtersMobileLabel",

    searchResults_search_seeLabel = "searchResults_search_seeLabel",
    searchResults_search_resultLabel = "searchResults_search_resultLabel",
    searchResults_search_resultsLabel = "searchResults_search_resultsLabel",
    globalPriceYHour = "globalPriceYHour",
    global_price_day = "global_price_day",
    global_price_week = "global_price_week",
    global_price_month = "global_price_month",
    global_price_year = "global_price_year",
    selectedCurrency = "selectedCurrency",
    minPriceError="minPriceError",
     negativePriceInvalidError= "negativePriceInvalidError",

    userDetailsTitle = "userDetailsTitle",
    addPhoneNumber = "addPhoneNumber",
    contactNumber = "contactNumber",
    codeContactNumber = "codeContactNumber",
    enableNotificationOnApp = "enableNotificationOnApp",
    userCurrency = "userCurrency",

    userName = "userName",
    loginEmail = "loginEmail",
    editContactDetails = "editContactDetails"
}

export default R 

