import React, {ReactElement, useEffect} from "react";
import {useInjection} from "@root/ioc.react";
import {IProvider} from "@di/viewmodels/ViewModelProvider";
import TYPES from "@di/viewmodels/ViewModelTypes";
import GetUserAccountUseCase from "@domain/usecases/user/GetUserAccountUseCase";
import {useGetOtherUserAccountViewModel} from "@root/framework/hooks/useGetOtherUserAccountViewModel";
import './UserInfoRow.scss';

interface UserInfoRowProps {
    userId: number;
}

export const UserInfoRow: React.FC<UserInfoRowProps> = ({ userId }) => {

    const getOtherUserAccountUseCase = useInjection<IProvider<GetUserAccountUseCase>>(TYPES.GetUserAccountUseCase).provide()

    const {
        stateGetUserAccount,
        getOtherUserAccount
    } = useGetOtherUserAccountViewModel(getOtherUserAccountUseCase);

    const  userAccount   = stateGetUserAccount.userAccount;

    useEffect(() => {
        getOtherUserAccount(userId);
    }, []);

    return (
        !stateGetUserAccount.isLoading ? (
        <div className="user-info-row">
            <img src={userAccount?.url_profile_img} alt="" className="profile-img" />
            <div className="user-details">
                <h2 className="full-name">{userAccount?.full_name}</h2>
             </div>
        </div>) : null
    );
}
