import React from "react";
import RecentReviewItem from "./RecentReviewItem";

// @ts-ignore
import frankImg from "@assets/frank.png";
// @ts-ignore
import kristiImg from "@assets/kristi.png";

import "./RecentReviews.scss";
import {t} from "i18next";
import R from "@strings/R";
import {useTranslation} from "react-i18next";



const RecentReviews: React.FC = (): JSX.Element => {

    const { t } = useTranslation();

    const recentReviewsData = [
        {
            id: 1,
            authorImage: frankImg,
            name: "Frank",
            location: "Dubai",
            description: t(R.firstRentalReview),
        },
        {
            id: 2,
            authorImage: kristiImg,
            name: "Kristi",
            location: "Abu Dhabi",
            description: t(R.secondRentalReview),
        },
    ];
    return (
        <div className="recent-review">
            <h5 className="recent-review__title">{t(R.recentRentalViews)}</h5>

            <div className="recent-review__content">
                {recentReviewsData.map((review) => {
                    return (
                        <RecentReviewItem
                            key={review.id}
                            authorImage={review.authorImage}
                            name={review.name}
                            location={review.location}
                            description={review.description}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default RecentReviews;
